import TextField, { TextFieldProps } from '@mui/material/TextField';
import { DatePicker, DatePickerProps, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment, { Moment } from 'moment';
import 'moment/locale/de';
import { useWizardContext } from '../contexts/importWizardContext/ImportWizardContextProvider';
import { Dispatch, SetStateAction, useState } from 'react';

export enum CustomDatePickerType {
  START_DATE,
  END_DATE,
}

interface IDatePickerProps extends Partial<DatePickerProps<Date | Moment | undefined, Moment>> {
  dateType: CustomDatePickerType;
  source?: string;
  global?: boolean;
  alwaysOn?: boolean;
  dispatch?: Dispatch<SetStateAction<Date | undefined>>;
  borderDate?: Date;
}
const CustomDatePicker = (props: IDatePickerProps) => {
  const { dateRange, setDateRange } = useWizardContext();

  const endDate = dateRange.selectedEndDate;
  const startDate = dateRange.selectedStartDate;

  let dateState: Date | undefined;
  let defaultDate: Date | undefined;
  switch (props.dateType) {
    case CustomDatePickerType.END_DATE:
      dateState = endDate;
      defaultDate = dateRange.endDate;
      break;
    case CustomDatePickerType.START_DATE:
    default:
      dateState = startDate;
      defaultDate = dateRange.startDate;
      break;
  }
  const [date, setDate] = useState(dateState);
  const displayDate = date === undefined ? defaultDate : date;

  let { borderDate } = props;
  if (props.global) {
    borderDate = props.dateType === CustomDatePickerType.START_DATE ? endDate : startDate;
  }

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale='de'>
      <DatePicker
        {...props}
        value={displayDate}
        minDate={moment(dateRange.startDate)}
        maxDate={moment(dateRange.endDate)}
        openTo='year'
        views={['year', 'month', 'day']}
        onChange={(value: Moment | null) => {
          if (value === null) {
            return;
          }
          const dateValue = value.toDate();
          setDate(dateValue);
          if (props.global) {
            setDateRange((prevDateRage) => {
              return props.dateType === CustomDatePickerType.START_DATE
                ? { ...prevDateRage, selectedStartDate: dateValue }
                : { ...prevDateRage, selectedEndDate: dateValue };
            });
          } else if (props.dispatch !== undefined) {
            props.dispatch(dateValue);
          }
        }}
        shouldDisableDate={(day: Moment) => {
          return props.dateType === CustomDatePickerType.START_DATE
            ? borderDate !== undefined && day.isSameOrAfter(borderDate)
            : borderDate !== undefined && day.isSameOrBefore(borderDate);
        }}
        shouldDisableMonth={(month: Moment) => {
          return props.dateType === CustomDatePickerType.START_DATE
            ? borderDate !== undefined && month.isAfter(borderDate, 'month')
            : borderDate !== undefined && month.isBefore(borderDate, 'month');
        }}
        shouldDisableYear={(year: Moment) => {
          return props.dateType === CustomDatePickerType.START_DATE
            ? (borderDate !== undefined && year.isAfter(borderDate, 'year')) ||
                (year.isSame(endDate, 'year') &&
                  moment(borderDate).startOf('year').isSame(borderDate, 'day'))
            : (borderDate !== undefined && year.isBefore(borderDate, 'year')) ||
                (year.isSame(borderDate, 'year') &&
                  moment(borderDate).endOf('year').isSame(borderDate, 'day'));
        }}
        renderInput={(params: TextFieldProps) => (
          <div
            style={{
              position: 'relative',
              display: 'inline-block',
            }}
            className='filter-clear-div'>
            <TextField
              {...params}
              variant='outlined'
              onKeyDown={(event) => event.preventDefault()}
              sx={{
                width: 170,
                mt: 0,
                mr: 1,
                mb: 1,
                '& .MuiInputBase-root': {
                  pointerEvents: 'none',
                },

                '& .MuiInputBase-root button': {
                  pointerEvents: 'all',
                },
              }}
            />
          </div>
        )}
      />
    </LocalizationProvider>
  );
};

export default CustomDatePicker;
