export const checkEqualMonthAndYear = (date1: Date, date2: Date) => {
  return date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear();
};

export const calculateAbsoluteDeltaInPercent = (evrValue: number, gbsValue: number) => {
  if (evrValue === 0 && gbsValue !== 0) {
    return 1;
  } else if (evrValue === 0 && gbsValue === 0) {
    return 0;
  } else {
    return Math.abs(1 - gbsValue / evrValue);
  }
};

export const calculateAbsoluteDeltaInPercentWithGbsMappedValue = (
  evrValue: number,
  gbsValue: number,
  gbsMappedValue: number,
) => {
  return calculateAbsoluteDeltaInPercent(evrValue, gbsValue + gbsMappedValue);
};

export const getDateRangeFromGbsData = (dateArray: Date[]) => {
  const timeArray = dateArray.map((date) => date.getTime());
  return {
    startDate: new Date(Math.min(...timeArray)),
    endDate: new Date(Math.max(...timeArray)),
  };
};

export const isInDateRange = (date: Date, start: Date, end: Date) => {
  if (date === undefined || start === undefined || end === undefined) {
    return false;
  }
  return (
    (date.getFullYear() > start.getFullYear() ||
      (date.getFullYear() === start.getFullYear() && date.getMonth() >= start.getMonth())) &&
    (date.getFullYear() < end.getFullYear() ||
      (date.getFullYear() === end.getFullYear() && date.getMonth() <= end.getMonth()))
  );
};
