import { Box } from '@mui/material';
import SciComparisonTabList from './SciComparisonTabList';
import { GUVContext } from './SciComparisonView';

const SciComparisonTabsProfitAndLossAccount = () => {
  return (
    <Box>
      <GUVContext.Provider value={true}>
        <SciComparisonTabList resourceName='profitAndLossComparsionData' />
      </GUVContext.Provider>
    </Box>
  );
};

export default SciComparisonTabsProfitAndLossAccount;
