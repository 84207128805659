/* eslint-disable @typescript-eslint/no-explicit-any */
import Dexie from 'dexie';
import {
  CreateParams,
  DataProvider,
  DeleteManyParams,
  DeleteParams,
  GetListParams,
  GetManyParams,
  GetManyReferenceParams,
  GetOneParams,
  RaRecord,
  UpdateManyParams,
  UpdateParams,
} from 'react-admin';
import log from 'loglevel';
import ComparsionProfitAndLossAccountDataType from '../../entities/comparison/ComparsionProfitAndLossAccountDataType';
import ComparisonBaseDataType from '../../entities/comparison/ComparisonBaseDataType';
import { importDB, exportDB } from 'dexie-export-import';

const DexieDataProvider = ({
  databaseName,
  databaseVersion,
  databaseStores,
}: DexieDataProviderParams): DataProvider => {
  const db = new Dexie(databaseName);
  db.version(databaseVersion).stores(databaseStores);

  return {
    getList: (resource: string, params: GetListParams) => {
      const {
        filter,
        pagination: { page, perPage },
        sort: { order, field },
      } = params;

      const table = db.table(resource);
      let collection = table.toCollection();

      if (order.toLowerCase() === 'desc') {
        collection = table.orderBy(field).reverse();
      } else if (order.toLocaleLowerCase() === 'asc') {
        collection = table.orderBy(field);
      }

      return collection
        .filter((entry) => {
          if (filter !== undefined) {
            for (const property in filter) {
              const filterValue = filter[property];

              // Skip when filter value is for the deviation filter
              if (property === 'deviation') {
                continue;
              }

              const entryValue = entry[property];
              let entryMatchesFilter;
              if (entryValue === undefined) {
                continue;
              }

              if (typeof filterValue === 'string' && typeof entryValue === 'string') {
                entryMatchesFilter = (entryValue as string).includes(filterValue);
              } else if (typeof filterValue === 'number' && typeof entryValue === 'string') {
                entryMatchesFilter = (entryValue as string).includes(String(filterValue));
              } else {
                entryMatchesFilter = entryValue === filterValue;
              }

              if (!entryMatchesFilter) {
                return false;
              }
            }
          }
          return true;
        })
        .toArray()
        .then((data) => {
          const count = data.length;

          data = (
            order === 'AlphaNumerical'
              ? data.sort(sortComparisonPositionNumberAlphanumerical)
              : data
          ).slice((page - 1) * perPage, Math.min(page * perPage, count));

          // Date filter on data values
          const startDate = filter['startDate'] ? new Date(filter['startDate']) : undefined;
          const endDate = filter['endDate'] ? new Date(filter['endDate']) : undefined;
          if (startDate !== undefined || endDate !== undefined) {
            data = data.map((entry) => {
              if (
                entry === undefined ||
                entry.values === undefined ||
                !Array.isArray(entry.values)
              ) {
                return entry;
              }

              const filteredValuesArray = entry.values.filter((value: any) => {
                if (value.date !== undefined) {
                  const currentDate = value.date as Date;
                  currentDate.setHours(0, 0, 0, 0);
                  startDate?.setHours(0, 0, 0, 0);
                  endDate?.setHours(0, 0, 0, 0);
                  if (startDate !== undefined && endDate !== undefined) {
                    return startDate <= currentDate && endDate >= currentDate;
                  }
                  if (startDate !== undefined) {
                    return startDate <= currentDate;
                  }
                  if (endDate !== undefined) {
                    return endDate >= currentDate;
                  }
                }
              });
              return { ...entry, values: filteredValuesArray };
            });
          }

          // Deviation filter
          const { deviation } = filter;
          if (deviation !== undefined) {
            data = data.filter((entry) => {
              if (Array.isArray(entry.values) && typeof deviation === 'number') {
                const entryExceedsThreshold = (entry.values as any[]).some((value) => {
                  if (typeof value.value === 'number') {
                    return (deviation as number) < value.value * 100;
                  }
                });
                if (!entryExceedsThreshold) {
                  return false;
                }
              }
              return true;
            });
          }

          return {
            data: data,
            page: page,
            total: count,
          };
        });
    },
    getOne: (resource: string, params: GetOneParams) => {
      return db
        .table(resource)
        .get(params.id)
        .then((data) => {
          return { data };
        });
    },
    getMany: (resource: string, params: GetManyParams) => {
      return db
        .table(resource)
        .bulkGet(params.ids)
        .then((data) => {
          return { data };
        });
    },
    getManyReference: (resource: string, params: GetManyReferenceParams) => {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { target, id } = params;
      const { page, perPage } = params.pagination;
      const { field, order } = params.sort;
      const offset = page > 1 ? (page - 1) * perPage : 0;

      const collection = db
        .table(resource)
        .orderBy(field)
        .filter((entry) => entry[target] === id);

      if (order.toLowerCase() === 'desc') {
        collection.reverse();
      }

      return collection.count((total) =>
        collection
          .offset(offset)
          .limit(perPage)
          .toArray((data) => ({
            data,
            total,
            pageInfo: {
              // TODO erde: Check if this is correct
              hasNextPage: (page + 1) * perPage < total,
              hasPreviousPage: page > 0,
            },
          })),
      );
    },
    update: (resource: string, params: UpdateParams) => {
      return db
        .table(resource)
        .update(params.id, params.data)
        .then(() => ({ data: { ...params.previousData, ...params.data } }));
    },
    updateMany: (resource: string, params: UpdateManyParams) => {
      return db
        .table(resource)
        .where('id')
        .anyOf(...params.ids)
        .modify(params.data)
        .then(() => ({ data: { ...params.data } }));
    },
    create: (resource: string, params: CreateParams) => {
      return db
        .table(resource)
        .put(params.data)
        .then((id) => ({ data: { ...params.data, id } }));
    },
    createMany: <RecordType extends RaRecord>(
      resource: string,
      params: CreateManyParams,
    ): Promise<CreateManyResult<RecordType>> => {
      return db
        .table(resource)
        .bulkAdd(params.data)
        .then(() => ({ data: params.data }))
        .catch(Dexie.BulkError, (error) => {
          // Explicitely catching the bulkAdd() operation makes those successful
          // additions commit despite that there were errors.
          log.error('Fehler beim Speichern von Datenmengen', error);
          return { data: error };
        });
    },
    delete: (resource: string, params: DeleteParams) => {
      return db
        .table(resource)
        .delete(params.id)
        .then(() => ({ data: { ...params.previousData, id: params.id } }));
    },
    deleteMany: (resource: string, params: DeleteManyParams) => {
      return db
        .table(resource)
        .bulkDelete(params.ids)
        .then(() => ({ data: params.ids }));
    },
    getUserProfile: () => {
      const storedProfile = localStorage.getItem('profile');
      if (storedProfile !== null) {
        return Promise.resolve({
          data: JSON.parse(storedProfile),
        });
      } else {
        // No profile yet, return a default one
        return Promise.resolve({
          data: {
            id: 'unique-id',
            fullName: '',
            avatar: '',
          },
        });
      }
    },
    updateUserProfile: (data: RaRecord) => {
      localStorage.setItem(
        'profile',
        JSON.stringify({
          fullName: data.fullName,
          id: data.id,
          avatar: data.avatar,
        }),
      );
      return Promise.resolve({ data });
    },
    clearTableOfResource: (resource: string) => {
      if (db.tables.length === 0) {
        return Promise.resolve({ data: resource });
      }
      return db
        .table(resource)
        .clear()
        .then(() => ({ data: resource }));
    },
    getDatabaseDump: () => {
      return exportDB(db, { prettyJson: true }).then((blob: Blob) => ({ data: blob }));
    },
    importDatabaseDump: (blob: Blob, callback?: (progress: any) => void) => {
      return importDB(blob, { clearTablesBeforeImport: true, progressCallback: callback }).then(
        () => ({ data: null }),
      );
    },
  };
};

const sortComparisonPositionNumberAlphanumerical = (
  a: ComparsionProfitAndLossAccountDataType | ComparisonBaseDataType,
  b: ComparsionProfitAndLossAccountDataType | ComparisonBaseDataType,
) => {
  return new Intl.Collator(undefined, { numeric: true, sensitivity: 'accent' }).compare(
    a.positionNumber,
    b.positionNumber,
  );
};

interface CreateManyParams<T = any> {
  data: T[];
  meta?: any;
}

interface CreateManyResult<RecordType extends RaRecord = any> {
  data?: RecordType[];
}
export interface DexieDataProviderParams {
  databaseName: string;
  databaseVersion: number;
  databaseStores: Record<string, string>;
}

export default DexieDataProvider;
