import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SciComparisonTabsBookValues from './SciComparisonTabsBookValues';
import SciComparisonTabsInterestResult from './SciComparisonTabsInterestResult';
import SciComparisonTabsProfitAndLossAccount from './SciComparisonTabsProfitAndLossAccount';
import { createContext } from 'react';

// TODO erde: CSS solution with fixed width not ideal, find better solution

export const GUVContext = createContext(false);

const SciComparisonView = () => (
  <Box mt={2} sx={{ width: '90vw' }}>
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel1a-content'
        id='panel1a-header'>
        <Typography variant='h4'>Gewinn- und Verlustrechnung</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <SciComparisonTabsProfitAndLossAccount />
      </AccordionDetails>
    </Accordion>
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel1a-content'
        id='panel1a-header'>
        <Typography variant='h4'>Buchwerte / Nominalvolumen</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <SciComparisonTabsBookValues />
      </AccordionDetails>
    </Accordion>
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls='panel2a-content'
        id='panel2a-header'>
        <Typography variant='h4'>Zinsergebnis</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <SciComparisonTabsInterestResult />
      </AccordionDetails>
    </Accordion>
  </Box>
);

export default SciComparisonView;
