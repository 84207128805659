/* eslint-disable camelcase */
import { TranslationMessages } from 'ra-core';

const germanMessages: TranslationMessages = {
  ra: {
    action: {
      add_filter: 'Filter hinzufügen',
      add: 'Hinzufügen',
      analyse: 'Analysieren',
      back: 'Zurück',
      bulk_actions: '1 item selected |||| %{smart_count} items selected',
      cancel: 'Abbrechen',
      clear_input_value: 'Zurücksetzen',
      clone: 'Clone',
      confirm: 'Bestätigen',
      create: 'Erstellen',
      create_item: 'Erstelle %{item}',
      delete: 'Löschen',
      edit: 'Bearbeiten',
      export: 'Export',
      list: 'Liste',
      refresh: 'Aktualisieren',
      remove_filter: 'Filter entfernen',
      remove: 'Entfernen',
      save: 'Speichern',
      search: 'Suchen',
      select_all: 'Alle selektieren',
      select_row: 'Reihe selektieren',
      show: 'Zeigen',
      sort: 'Sortieren',
      undo: 'Rückgängig',
      unselect: 'Unselect',
      expand: 'Erweitern',
      close: 'Schließen',
      open_menu: 'Menü öffnen',
      close_menu: 'Menü schließen',
      update: 'Update',
      move_up: 'Move up',
      move_down: 'Move down',
      open: 'Öffnen',
      toggle_theme: 'Toggle Theme',
      remove_all_filters: 'Filter zurücksetzen',
      compare: 'Vergleich',
      clear_array_input: 'Liste leeren',
      select_columns: 'Spalten',
    },
    boolean: {
      true: 'Ja',
      false: 'Nein',
      null: ' ',
    },
    page: {
      create: '%{name} erstellen',
      dashboard: 'Übersicht',
      edit: '%{name} #%{id}',
      error: 'Es ist ein Fehler aufgetreten',
      list: '%{name}',
      loading: 'Lade...',
      not_found: 'Nicht gefunden',
      show: '%{name} #%{id}',
      empty: 'Keine Einträge (%{name})',
      invite: 'Eintrag hinzufügen?',
    },
    input: {
      file: {
        upload_several: 'Drop some files to upload, or click to select one.',
        upload_single: 'Drop a file to upload, or click to select it.',
      },
      image: {
        upload_several: 'Drop some pictures to upload, or click to select one.',
        upload_single: 'Drop a picture to upload, or click to select it.',
      },
      references: {
        all_missing: 'Unable to find references data.',
        many_missing:
          'At least one of the associated references no longer appears to be available.',
        single_missing: 'Associated reference no longer appears to be available.',
      },
      password: {
        toggle_visible: 'Hide password',
        toggle_hidden: 'Show password',
      },
    },
    message: {
      about: 'About',
      are_you_sure: 'Are you sure?',
      bulk_delete_content:
        'Are you sure you want to delete this %{name}? |||| Are you sure you want to delete these %{smart_count} items?',
      bulk_delete_title: 'Delete %{name} |||| Delete %{smart_count} %{name}',
      bulk_update_content:
        'Are you sure you want to update this %{name}? |||| Are you sure you want to update these %{smart_count} items?',
      bulk_update_title: 'Update %{name} |||| Update %{smart_count} %{name}',
      delete_content: 'Are you sure you want to delete this item?',
      delete_title: 'Delete %{name} #%{id}',
      details: 'Details',
      error: "A client error occurred and your request couldn't be completed.",
      invalid_form: 'The form is not valid. Please check for errors',
      loading: 'The page is loading, just a moment please',
      no: 'No',
      not_found: 'Either you typed a wrong URL, or you followed a bad link.',
      yes: 'Yes',
      unsaved_changes: "Some of your changes weren't saved. Are you sure you want to ignore them?",
      auth_error: 'A error occurred while validating the authentication token.',

      clear_array_input: 'Are you sure you want to clear the whole list?',
    },
    navigation: {
      no_results: 'Keine Ergebnisse gefunden',
      no_more_results: 'The page number %{page} is out of boundaries. Try the previous page.',
      page_out_of_boundaries: 'Page number %{page} out of boundaries',
      page_out_from_end: 'Cannot go after last page',
      page_out_from_begin: 'Cannot go before page 1',
      page_range_info: '%{offsetBegin}-%{offsetEnd} of %{total}',
      partial_page_range_info: '%{offsetBegin}-%{offsetEnd} of more than %{offsetEnd}',
      current_page: 'Seite %{page}',
      page: 'Gehe zu Seite %{page}',
      first: 'Erste Seite',
      last: 'Letzte Seite',
      next: 'Nächste Seite',
      previous: 'Vorherige Seite',
      page_rows_per_page: 'Zeilen pro Seite:',
      skip_nav: 'Wechsle zum Inhalt',
    },
    sort: {
      sort_by: 'Sortiert nach %{field} %{order}',
      ASC: 'ascending',
      DESC: 'descending',
    },
    auth: {
      auth_check_error: 'Bitte einloggen',
      user_menu: 'Profil',
      username: 'Benutzername',
      password: 'Passwort',
      sign_in: 'Anmelden',
      sign_in_error: 'Authentifizierung fehlgeschlagen',
      logout: 'Logout',
    },
    notification: {
      updated: 'Element updated |||| %{smart_count} elements updated',
      created: 'Element created',
      deleted: 'Element deleted |||| %{smart_count} elements deleted',
      bad_item: 'Incorrect element',
      item_doesnt_exist: 'Element does not exist',
      http_error: 'Server communication error',
      data_provider_error: 'dataProvider error. Check the console for details.',
      i18n_error: 'Cannot load the translations for the specified language',
      canceled: 'Action cancelled',
      logged_out: 'Your session has ended, please reconnect.',
      not_authorized: "You're not authorized to access this resource.",
      analysis_complete: 'Die Analyse war erfolgreich.',
      upload_successful: 'Die Datei wurde erfolgreich hochgeladen.',
    },
    validation: {
      required: 'notwendig',
      minLength: 'Must be %{min} characters at least',
      maxLength: 'Must be %{max} characters or less',
      minValue: 'Must be at least %{min}',
      maxValue: 'Must be %{max} or less',
      number: 'Must be a number',
      email: 'Keine gültige Email-Adresse',
      oneOf: 'Must be one of: %{options}',
      regex: 'Must match a specific format (regexp): %{pattern}',
    },
    saved_queries: {
      label: 'Gespeicherte Abfragen',
      query_name: 'Abfrage',
      new_label: 'Speichere aktuelle Abfrage...',
      new_dialog_title: 'Speichere Abfrage als',
      remove_label: 'Remove saved query',
      remove_label_with_name: 'Remove query "%{name}"',
      remove_dialog_title: 'Remove saved query?',
      remove_message: 'Are you sure you want to remove that item from your list of saved queries?',
      help: 'Filter the list and save this query for later',
    },
  },
  'ra-audit-log': {
    event: {
      create: 'Erstellt %{resource} %{name}',
      delete: 'Gelöscht %{resource} %{name}',
      deleteMany: 'Gelöscht %{resource} %{ids}',
      update: 'Aktualisiert %{resource} %{name}',
      updateMany: 'Aktualisiert %{resource} %{ids}',
    },
    inline_event: {
      create: 'erstellt %{resource} %{name}',
      delete: 'gelöscht %{resource} %{name}',
      deleteMany: 'gelöscht %{resource} %{ids}',
      update: 'aktualisiert %{resource} %{name}',
      updateMany: 'aktualisiert %{resource} %{ids}',
    },
    record_event: {
      create: 'hat diese Resource erstellt',
      delete: 'hat diese Resource gelöscht',
      update: 'aktualisiert field %{fields} |||| aktualisiert fields %{fields}',
    },
    record_many_fields_events: {
      create: 'Created this resource',
      delete: 'gelöscht this resource',
      update:
        'aktualisiert Feld %{fields} und 1 anderes |||| aktualisiert Felder %{fields} und %{smart_count} andere',
    },
    author: '{fullName}',
    period_filters: {
      today: 'Heute',
      this_week: 'Diese Woche',
      this_month: 'Diesen Monat',
      this_quarter: 'Dieses Quartal',
    },
    filter: {
      author: 'Nach Bearbeiter',
      date: 'nach Datum',
      resource: 'nach Ressource',
    },
    fields: {
      author: 'Bearbeiter',
      action: 'Aktion',
      date: 'Datum',
      resource: 'Resource',
    },
  },
  'ra-form-layout': {
    action: {
      next: 'Weiter',
      previous: 'Zurück',
      analyse: 'Analysieren',
      reset: 'Zurücksetzen',
    },
  },
};

export default germanMessages;
