import log from 'loglevel';
import { DataProvider } from 'react-admin';
import { intToExcelCol } from 'excel-column-name';

export const OProcess = {
  ImportGBSGuV: 'Import GBS GuV',
  ImportGBSBalance: 'Import GBS Volumina- und Zinsergebnis',
  ImportEVRGuV: 'Import EVR GuV',
  ImportEVRBalance: 'Import EVR Volumina- und Zinsergebnis',
  Mapping: 'Analyse (Mapping)',
  Merging: 'Analsye (Merge)',
} as const;

type EProcess = typeof OProcess[keyof typeof OProcess];

export const OAction = {
  Discarded: 'Verworfen',
  Ignored: 'Ignoriert',
  Error: 'Fehler',
  Changed: 'Geändert',
} as const;

type EAction = typeof OAction[keyof typeof OAction];

export const OReason = {
  DuplicateTimeSeries: 'EVR-Eintrag für Zeitreihe doppelt vorhanden',
  InvalidBiPo: 'nicht zuordbare BiPo',
  InvalidContractPositions: 'fehlerhafte Vertragspositionsanzahl',
  InvalidPositionNumber: 'fehlerhafte Positionsnummer',
  InvalidKeyFigure: 'fehlerhafte Kennzahl',
  InvalidUnit: 'fehlerhafte Einheit',
  InvalidDateFormat: 'fehlerhaftes Datumsformat',
  InvalidSum: 'fehlerhaftes Summenergebnis',
  InvalidBusinessType: 'irrelevanter Buisnesstyp',
  InvalidDateValues: 'fehlerhafte Datumsdaten',
  InvalidBalancePosition: 'fehlerhafte Bilanzposition',
  InvalidEVRAccountNumber: 'nicht zuweisbare Kontonummer aus EVR',
  MissingPositionNumber: 'fehlende Positionsnummer',
  MissingKeyFigure: 'fehlende Kennzahl',
  MissingEvrElement: 'fehlendes EVR-Element',
  MissingGbsElement: 'fehlendes GBS-Element',
  IgnoredValues: 'ignorierte Positionsnummer',
  EmptyCell: 'kein Zelleninhalt',
  ZeroValues: 'nur 0 Werte enthalten',
  ChangedToZero: 'auf 0 geändert',
} as const;

type EReason = typeof OReason[keyof typeof OReason];

export const generateChoicesArray = (enumName: string) => {
  const choices: { id: string; name: string }[] = [];
  let enumObject: typeof OProcess | typeof OAction | typeof OReason | undefined = undefined;
  switch (enumName) {
    case 'reason':
      enumObject = OReason;
      break;
    case 'process':
      enumObject = OProcess;
      break;
    case 'action':
      enumObject = OAction;
      break;
  }

  if (enumObject !== undefined) {
    for (const property in enumObject) {
      choices.push({
        id: enumObject[property as keyof typeof enumObject],
        name: enumObject[property as keyof typeof enumObject],
      });
    }
  }

  return choices;
};

export type LoggingEntry = {
  id: number;
  positionNumber: string;
  keyFigure: string;
  process: string;
  action: string;
  reason: string;
  timestamp: string;
};

let logDataProvider: DataProvider | undefined = undefined;

export const setLogDataProvider = (dataProvider: DataProvider) => {
  logDataProvider = dataProvider;
};

const hashCode = (string: string) => {
  let hash = 0;

  for (let i = 0; i < string.length; i++) {
    const code = string.charCodeAt(i);
    hash = (hash << 5) - hash + code;
    hash = hash & hash; // Convert to 32bit integer
  }

  return hash;
};

const createIdentifier = (
  positionNumber: string,
  keyFigure: string,
  process: string,
  reason: string,
) => {
  return hashCode(positionNumber + keyFigure + process + reason);
};

const createReasonString = (reason: EReason, columnIndex?: number, rowIndex?: number) => {
  return (
    reason +
    (columnIndex !== undefined && rowIndex !== undefined
      ? ' in Zelle ' + intToExcelCol(columnIndex + 1) + ' ' + (rowIndex + 1)
      : '')
  );
};

export const createLogEntry = (
  positionNumber: string,
  keyFigure: string,
  process: EProcess,
  action: EAction,
  reason: EReason,
  columnIndex?: number,
  rowIndex?: number,
) => {
  if (logDataProvider !== undefined) {
    const reasonString = createReasonString(reason, columnIndex, rowIndex);

    logDataProvider
      .create('loggingData', {
        data: {
          id: createIdentifier(positionNumber, keyFigure, process, reasonString),
          positionNumber: positionNumber,
          keyFigure: keyFigure,
          process: process,
          action: action,
          reason: reasonString,
          timestamp: new Date().toLocaleString(),
        },
      })
      .catch((e: Error) => log.error('Anlage des Logeintrags fehlgeschlagen: ' + e));
  }
};

export const updateLogEntry = (
  logEntry: LoggingEntry,
  positionNumber: string,
  keyFigure: string,
  process: EProcess,
  reason: EReason,
  columnIndex?: number,
  rowIndex?: number,
) => {
  if (logDataProvider !== undefined) {
    if (logEntry.id === undefined || logEntry.id === 0) {
      logEntry.id = createIdentifier(
        logEntry.positionNumber,
        logEntry.keyFigure,
        logEntry.process,
        logEntry.reason,
      );
    }
    logDataProvider
      .update('loggingData', {
        id: createIdentifier(
          positionNumber,
          keyFigure,
          process,
          createReasonString(reason, columnIndex, rowIndex),
        ),
        data: logEntry,
        previousData: null,
      })
      .catch((e: Error) => log.error('Aktualisieren des Logeintrags fehlgeschlagen: ' + e));
  }
};

export const deleteLogEntry = (
  positionNumber: string,
  keyFigure: string,
  process: EProcess,
  reason: EReason,
  columnIndex?: number,
  rowIndex?: number,
) => {
  if (logDataProvider !== undefined) {
    logDataProvider
      .delete('loggingData', {
        id: createIdentifier(
          positionNumber,
          keyFigure,
          process,
          createReasonString(reason, columnIndex, rowIndex),
        ),
      })
      .catch((e: Error) => log.error('Löschen des Logeintrags fehlgeschlagen: ' + e));
  }
};

export const clearLog = () => {
  if (logDataProvider !== undefined) {
    // Zuruecksetzen der Log-Eintraege
    logDataProvider.clearTableOfResource('loggingData');
  }
};

export const createErrorLogEntry = (errorMessage: string) => {
  if (logDataProvider !== undefined) {
    logDataProvider
      .create('errorData', {
        data: {
          id: hashCode(errorMessage),
          errorMessage,
          timestamp: new Date().toLocaleString(),
        },
      })
      .catch((e: Error) => log.error('Anlage des Fehlerlogeintrags fehlgeschlagen: ' + e));
  }
};

export const clearErrorLog = () => {
  if (logDataProvider !== undefined) {
    // Zuruecksetzen der Log-Eintraege
    logDataProvider.clearTableOfResource('errorData');
  }
};
