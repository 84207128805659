import { DataProvider } from 'react-admin';

import ComparsionProfitAndLossAccountDataType from '../comparison/ComparsionProfitAndLossAccountDataType';
import { EvrProfitAndLossAccountType } from '../importer/EvrProfitAndLossAccountImport';
import { GbsProfitAndLossAccountType } from '../importer/GbsProfitAndLossAccountImport';
import { v4 as uuidv4 } from 'uuid';
import DataPointType from '../comparison/DataPointType';
import {
  calculateAbsoluteDeltaInPercent,
  checkEqualMonthAndYear,
  getDateRangeFromGbsData,
  isInDateRange,
} from './mergeUtility';
import log from 'loglevel';
import { createLogEntry, OAction, OProcess, OReason } from '../logging/loggingUtility';

const gbsToEvrMergeMap: Map<string, string> = new Map([
  ['1', 'Zinsüberschuss'],
  ['1.1', ' Zinsertrag'],
  ['1.2', ' Zinsaufwand'],
  ['1b', ' Zinsergebnis aus Derivaten'],
  ['2', ' Provisionsüberschuss'],
  ['2.1', '  Provisionsertrag'],
  ['2.2', '  Provisionsaufwand'],
  ['3.1', ' sonstiger ordentlicher Ertrag'],
  ['3.2', ' sonstiger ordentlicher Aufwand'],
  ['4', ' Verwaltungsaufwand'],
  ['4.1', '  Personalaufwand'],
  ['4.2', '  Sachaufwand'],
  ['5', 'Nettoergebnis aus Finanzgeschäften'],
  ['6', 'Betriebsergebnis vor Bewertung'],
  ['7', 'Bewertungsergebnis I'],
  ['7.1', ' Wertpapiergeschäft'],
  ['7.2', ' Kreditgeschäft'],
  ['7.3', ' Sonstige'],
  ['7.4', 'Veränderung Vorsorgereserve'],
  ['7.4.1', '  Veränder. Vorsorgeres §340f HGB'],
  ['7.4.2', '  Veränder. Vorsorgeres §340g + e HGB'],
  ['8', 'Betriebsergebnis nach Bewertung I'],
  ['9', 'Neutrales Ergebnis'],
  ['9.1', ' Neutraler Ertrag'],
  ['9.2', ' Neutraler Aufwand'],
  ['14', 'Ergebnis vor Steuern I'],
  ['15', 'Gewinnabhängige Steuern'],
  ['16', 'Jahresergebnis I'],
  ['17', 'Gewinnausschüttung'],
  ['18', 'Veränderung Eigenkapital'],
]);

export const merge = (
  gbsImportedData: GbsProfitAndLossAccountType[],
  evrImportedData: EvrProfitAndLossAccountType[],
) => {
  const profitAndLossResult: Map<string, ComparsionProfitAndLossAccountDataType> = new Map();

  if (
    gbsImportedData === undefined ||
    evrImportedData === undefined ||
    evrImportedData.length === 0 ||
    gbsImportedData.length === 0
  ) {
    const error = new Error('Keine Daten zum Merge für GuV von EVR bzw. GBS vorhanden.');
    log.error(error.message);
    throw error;
  }

  const gbsDateTimeArray: Date[] = Array.from(
    new Set(gbsImportedData.map((entry) => entry.date.getTime())),
  ).map((dateInMillis) => new Date(dateInMillis));

  // Get date range from gbs
  const { startDate: startDate, endDate: endDate } = getDateRangeFromGbsData(
    gbsImportedData.map((entry) => entry.date),
  );

  // At this point it is considered an error when startDate or endDate is undefined
  evrImportedData = evrImportedData.filter((entry) =>
    isInDateRange(entry.date, startDate, endDate),
  );

  gbsImportedData.forEach((gbsElement) => {
    const {
      accountNumber: gbsAccountNumber,
      id: gbsId,
      date: gbsDate,
      value: gbsValue,
      account: gbsAccountName,
    } = gbsElement;

    if (gbsToEvrMergeMap.has(gbsAccountNumber)) {
      const gbsAccount = gbsToEvrMergeMap.get(gbsAccountNumber)?.replace(/\s+/g, '');
      const evrElementIndex = evrImportedData.findIndex(
        (element) =>
          gbsAccount === element.account.replace(/\s+/g, '') &&
          checkEqualMonthAndYear(gbsDate, element.date),
      );

      const [{ value: evrValue, id: evrId }] =
        evrElementIndex !== -1
          ? evrImportedData.splice(evrElementIndex, 1)
          : [{ value: 0, id: uuidv4() }];

      const dataPointValue: DataPointType = {
        value: calculateAbsoluteDeltaInPercent(evrValue, gbsValue),
        date: gbsDate,
        evrData: {
          id: evrId,
          positionNumber: '',
          value: evrValue,
        },
        gbsData: [{ id: gbsId, positionNumber: gbsAccountNumber, value: gbsValue }],
      };

      const cmpObject = profitAndLossResult.get(gbsAccountNumber);

      // compare object already exists
      if (cmpObject !== undefined) {
        const cmpValues = cmpObject.values;
        cmpValues.push(dataPointValue);

        // new compare object
      } else {
        const cmpObjectNew: ComparsionProfitAndLossAccountDataType = {
          id: uuidv4(),
          deviationReasonId: '',
          name: gbsAccountName,
          positionNumber: gbsAccountNumber,
          values: [dataPointValue],
        };

        profitAndLossResult.set(gbsAccountNumber, cmpObjectNew);
      }
    }
  });

  const getGbsAccountNumber = (evrAccount: string) => {
    const evrAccountReplaced = evrAccount.replace(/\s+/g, '');

    for (const entry of gbsToEvrMergeMap) {
      const [key, value] = entry;

      if (evrAccountReplaced === value.replace(/\s+/g, '')) {
        return key;
      }
    }
    return undefined;
  };

  // loop through remaining evr elements and fill with 0er gbs elements
  evrImportedData.forEach(({ value: evrValue, id: evrId, date: evrDate, account: evrAccount }) => {
    const accountNumber = getGbsAccountNumber(evrAccount);

    if (accountNumber === undefined) {
      // TODO add log in Verarbeitungsdialog
      createLogEntry(
        evrAccount,
        '',
        OProcess.Merging,
        OAction.Discarded,
        OReason.InvalidEVRAccountNumber,
      );
      log.error(
        `Dem Konto ${evrAccount} aus EVR konnte keine Kontonummer in GBS zugewiesen werden.`,
      );
    } else {
      const dataPointValue: DataPointType = {
        value: calculateAbsoluteDeltaInPercent(evrValue, 0),
        date: evrDate,
        evrData: {
          id: evrId,
          positionNumber: '',
          value: evrValue,
        },
        gbsData: [
          {
            id: uuidv4(),
            positionNumber: accountNumber,
            value: 0,
          },
        ],
      };

      const compareObject = profitAndLossResult.get(accountNumber);

      // compare object already exists
      if (compareObject !== undefined) {
        const compareObjectValues = compareObject.values;
        compareObjectValues.push(dataPointValue);

        // new compare object
      } else {
        const compareObjectNew: ComparsionProfitAndLossAccountDataType = {
          id: uuidv4(),
          deviationReasonId: '',
          name: '',
          positionNumber: accountNumber,
          values: [dataPointValue],
        };

        profitAndLossResult.set(accountNumber, compareObjectNew);
      }
    }
  });

  // Add missing evr objects in date range to comparison data
  profitAndLossResult.forEach(({ positionNumber: positionNumber, values: values }) => {
    gbsDateTimeArray
      .filter(
        (date) =>
          values.find(
            (value) =>
              value.date.getMonth() === date.getMonth() &&
              value.date.getFullYear() === date.getFullYear(),
          ) === undefined,
      )
      .forEach((missingDate) => {
        const missingDataPointValue: DataPointType = {
          value: calculateAbsoluteDeltaInPercent(0, 0),
          date: missingDate,
          evrData: {
            id: uuidv4(),
            positionNumber: positionNumber,
            value: 0,
          },
          gbsData: [
            {
              id: uuidv4(),
              positionNumber: positionNumber,
              value: 0,
            },
          ],
        };

        values.push(missingDataPointValue);
      });
  });

  return Array.from(profitAndLossResult.values());
};

const mergeProfitAndLossAccount = (dataProvider: DataProvider) => {
  return new Promise<{ message: string }>((resolve) => {
    const gbsData = dataProvider.getList<GbsProfitAndLossAccountType>(
      'gbsProfitAndLostAccountData',
      {
        pagination: { page: 1, perPage: 1000000 },
        sort: { field: 'id', order: 'DESC' },
        filter: '',
      },
    );
    const evrData = dataProvider.getList<EvrProfitAndLossAccountType>(
      'evrProfitAndLostAccountData',
      {
        pagination: { page: 1, perPage: 1000000 },
        sort: { field: 'id', order: 'DESC' },
        filter: '',
      },
    );

    Promise.all([gbsData, evrData]).then(([gbsResult, evrResult]) => {
      const result = merge(gbsResult.data, evrResult.data);
      dataProvider.clearTableOfResource('profitAndLossComparsionData').then(() => {
        dataProvider
          .createMany('profitAndLossComparsionData', { data: result })
          .then(() => resolve({ message: 'ra.notification.analysis_complete' }));
      });
    });
  });
};

export default mergeProfitAndLossAccount;
