import i18nProvider from './providers/I18nProvider/I18nProvider';
import dataProvider from './providers/DexieDataProvider/SciDexieDataProvider';
import { Admin } from '@react-admin/ra-enterprise';
import { CustomRoutes, Resource } from 'react-admin';
import { QueryClient } from 'react-query';
import { Route } from 'react-router';
import SciLayout from './layout/SciLayout';
import { spkLightTheme, spkDarkTheme } from './layout/themes/SparkassenTheme';
import SciHandbuchPage from './pages/SciServicePages/SciHandbuchPage';
import SciImportStepper from './pages/ComparisonPage/SciImportStepper';
import SciComparisonView from './pages/ComparisonPage/SciComparisonView';
import SciSupportPage from './pages/SciServicePages/SciSupportPage';
import { clearErrorLog, setLogDataProvider } from './entities/logging/loggingUtility';
import SciLoggingList from './pages/SciLoggingPage/SciLoggingList';
import SciImportJSONPage from './pages/SciImportJSONPage/SciImportJSONPage';
import { useEffect } from 'react';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {
  useEffect(() => {
    setLogDataProvider(dataProvider);
    clearErrorLog();
  }, []);
  return (
    <Admin
      queryClient={queryClient}
      i18nProvider={i18nProvider}
      dashboard={SciImportStepper}
      disableTelemetry
      dataProvider={dataProvider}
      layout={SciLayout}
      theme={spkLightTheme}
      lightTheme={spkLightTheme}
      darkTheme={spkDarkTheme}>
      <CustomRoutes>
        <Route path='/import' element={<SciImportStepper />} />
        <Route path='/documentation' element={<SciHandbuchPage />} />
        <Route path='/compare' element={<SciComparisonView />} />
        <Route path='/support' element={<SciSupportPage />} />
        <Route path='/log' element={<SciLoggingList resourceName='loggingData' />} />
        <Route path='/importdexie' element={<SciImportJSONPage />} />
      </CustomRoutes>

      <Resource name='abweichungsgruende' />
      <Resource name='externalInterestResultComparisonData' />
      <Resource name='marginContributionComparisonData' />
      <Resource name='liquidityContributionComparisonData' />
      <Resource name='creditSpreadComparisonData' />
      <Resource name='structuralContributionComparisonData' />
      <Resource name='nominalVolumeAverageComparisonData' />
      <Resource name='bookValueAverageComparisonData' />
      <Resource name='bookValueUltimoComparisonData' />
      <Resource name='loggingData' />
    </Admin>
  );
};

export default App;
