import log from 'loglevel';
import { useState } from 'react';
import { Button, useDataProvider, useNotify } from 'react-admin';
import downloadJSON from '../entities/databaseBackup/downloadJSON';
import DownloadIcon from '@mui/icons-material/GetApp';

const DatabaseExportButton = () => {
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(false);
  const notify = useNotify();
  const handleDatabaseDump = async () => {
    setLoading(true);
    try {
      const result = await dataProvider.getDatabaseDump();
      downloadJSON(result.data, 'Export');
    } catch (error: unknown) {
      let message = '';
      if (error instanceof Error) {
        message = `Fehler beim Export: ${error.message}`;
      } else {
        message = 'Unbekannter Fehler';
      }
      log.error(message);
      notify(message, { type: 'error' });
    }
    setLoading(false);
  };
  return (
    <Button
      disabled={loading}
      onClick={handleDatabaseDump}
      label='Datenbankexport'
      sx={{ height: 56 }}>
      <DownloadIcon />
    </Button>
  );
};

export default DatabaseExportButton;
