import { MenuItemList, MenuItemNode, IconMenu } from '@react-admin/ra-navigation';
import { CardContent, Typography } from '@mui/material';

import SyncAltIcon from '@mui/icons-material/SyncAlt';
import BallotIcon from '@mui/icons-material/Ballot';
import SettingsSystemDaydreamIcon from '@mui/icons-material/SettingsSystemDaydream';

const SciMenu = () => (
  <IconMenu>
    <IconMenu.Item name='import' to='/import' label='Import' icon={<BallotIcon />} />
    <IconMenu.Item name='comparison' to='/compare' label='Vergleich' icon={<SyncAltIcon />} />
    <IconMenu.Item name='service' label='Service' icon={<SettingsSystemDaydreamIcon />}>
      <CardContent>
        {/* to get consistent spacing */}
        <Typography variant='h6' gutterBottom>
          Service
        </Typography>
        {/* Note that we must wrap our MenuItemNode components in a MenuItemList */}
        <MenuItemList>
          <MenuItemNode name='menu.documentation' to={'/documentation'} label='Dokumentation' />
          <MenuItemNode name='menu.support' to={'/support'} label='Support' />
          <MenuItemNode name='menu.log' to={'/log'} label='Verarbeitungsdetails' />
        </MenuItemList>
      </CardContent>
    </IconMenu.Item>
  </IconMenu>
);

export default SciMenu;
