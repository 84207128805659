import { ThemeOptions } from '@react-admin/ra-enterprise';

export const spkLightTheme: ThemeOptions = {
  palette: {
    mode: 'light',
    primary: {
      main: '#E30018',
    },
    secondary: {
      light: '#5f5fc4',
      main: '#E30018',
      dark: '#D9D9D9',
      contrastText: '#fff',
    },
    background: {
      default: '#f2f2f2',
    },
    success: {
      main: '#2e7d32',
    },
  },
  typography: {
    h2: {
      fontSize: '2rem',
    },
    h3: {
      fontSize: '1.5rem',
    },
    h4: {
      fontSize: '1.25rem',
    },
    h5: {
      fontSize: '1.1rem',
    },
  },
  shape: {
    borderRadius: 10,
  },
  components: {
    RaSidebar: {
      styleOverrides: {
        root: {
          '& .RaSidebar-drawerPaper': {},
          '& .RaSidebar-fixed': {
            zIndex: 1200,
            width: 76,
          },
          '&.MuiDrawer-docked .MuiPaper-root': {
            width: 76,
          },
          '&.RaSidebar-appBarCollapsed': {
            marginTop: '0px',
          },
        },
      },
    },
    RaMenuItemCategory: {
      styleOverrides: {
        root: {
          color: 'black',
        },
      },
    },
    RaLogin: {
      stylesOverride: {
        root: {
          // backgroundImage: 'radial-gradient(circle at 50% 14em, white 0%, red 60%, white 100%)',
          backgroundImage: 'url("spk.jpeg") !important', // Hintergrundbild auf dem Login-Bildschirm
        },
      },
    },
  },
};
export const spkDarkTheme: ThemeOptions = {
  palette: {
    mode: 'dark',
    primary: {
      main: '#E73230',
    },
    secondary: {
      main: '#E73230',
    },
  },
  components: {
    RaSidebar: {
      styleOverrides: {
        root: {
          '& .RaSidebar-drawerPaper': {},
          '& .RaSidebar-fixed': {
            zIndex: 1200,
            width: 76,
          },
          '&.MuiDrawer-docked .MuiPaper-root': {
            width: 76,
          },
        },
      },
    },
    RaMenuItemCategory: {
      styleOverrides: {
        root: {
          color: 'white',
        },
      },
    },
    RaLogin: {
      stylesOverride: {
        root: {
          // backgroundImage: 'radial-gradient(circle at 50% 14em, white 0%, red 60%, white 100%)',
          backgroundImage: 'url("spk.jpeg") !important', // Hintergrundbild auf dem Login-Bildschirm
        },
      },
    },
  },
};
