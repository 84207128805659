import React from 'react';

import {
  Button,
  CardActionArea,
  CardActions,
  CardMedia,
  Card,
  CardContent,
  Typography,
  Box,
} from '@mui/material';
import { Stack } from '@mui/system';

const FachSpez = () => {
  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardActionArea>
        <CardMedia
          component='img'
          height='140'
          image='./static/specification.webp'
          alt='Spezifikation'
        />
        <CardContent>
          <Typography gutterBottom variant='h5' component='div'>
            Spezifikation
          </Typography>
          <Typography variant='body2' color='text.secondary'>
            Das GAT-Tool bietet Ihnen eine einfache und schnelle Möglichkeit, die Ergebnisse beider
            Quellsysteme zu vergleichen. <br />
            Dabei genügt die Verarbeitung Ihrer Daten höchstmöglichen Sicherheitsansprüchen.
            <br />
            <br />
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button
          size='small'
          color='primary'
          onClick={() => {
            window.open('./static/Fachspez_GBS-EVR_Abgleichstool_V1.1.pdf', '_blank');
          }}>
          Fachliche Spezifikation
        </Button>
      </CardActions>
    </Card>
  );
};

const Anwendungsplattform = () => {
  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardActionArea>
        <CardMedia component='img' height='140' image='./static/plattform.webp' alt='Plattform' />
        <CardContent>
          <Typography gutterBottom variant='h5' component='div'>
            Anwendungsplattform
          </Typography>
          <Typography variant='body2' color='text.secondary'>
            Dieses Tool wurde auf einer Anwendungsplattform der PPI AG realisiert. Die Plattform
            setzt fachliche Vorgaben günstig und schnell in cloudnative Anwendungen um. Die
            besonderen Sicherheitsbedürfnisse der Finanzbranche werden dabei berücksichtigt.
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button
          size='small'
          color='primary'
          onClick={() => {
            window.open('https://info.ppi-cloud.de?origin=gat', '_blank');
          }}>
          Zum Angebot der PPI AG
        </Button>
      </CardActions>
    </Card>
  );
};

const SCIBetrieb = () => {
  return (
    <Card sx={{ maxWidth: 345 }}>
      <CardActionArea>
        <CardMedia component='img' height='140' image='./static/betrieb.webp' alt='Plattform' />
        <CardContent>
          <Typography gutterBottom variant='h5' component='div'>
            Betriebsumgebung
          </Typography>
          <Typography variant='body2' color='text.secondary'>
            Die optimierte Betriebsumgebung im Teamroom des Rollouts (Microsoft SharePoint)
            ermöglicht eine kostengünstige, schnelle und einfache Bereitstellung für die einzelnen
            Institute.
            <br />
            <br />
            <br />
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button
          size='small'
          color='primary'
          onClick={() => {
            window.open(
              'https://www.microsoft.com/de-de/microsoft-365/sharepoint/collaboration',
              '_blank',
            );
          }}>
          Mehr zu Microsoft SharePoint
        </Button>
      </CardActions>
    </Card>
  );
};

const SciHandbuchPage = () => (
  <Box sx={{ width: '80%' }}>
    <br />
    <Stack direction='row' spacing={2}>
      <FachSpez />
      <Anwendungsplattform />
      <SCIBetrieb />
    </Stack>
  </Box>
);
export default SciHandbuchPage;
