import { Box, Chip, Divider, Grid } from '@mui/material';
import DatabaseExportButton from '../../fields/DatabaseExportButton';
import SciImportJSONPage from '../SciImportJSONPage/SciImportJSONPage';

const SciSupportPage = () => (
  <>
    <Grid
      mt={1}
      container
      direction='row'
      spacing={4}
      rowSpacing={4}
      justifyContent='center'
      alignItems='flex-start'>
      <Grid item mt={2} mb={5} xs={12} sm={12} md={12} lg={6} xl={4}>
        <Box mt={3} mb={3}>
          <Divider>
            <Chip label='Export aller Daten' />
          </Divider>
          <DatabaseExportButton />
          <SciImportJSONPage />
        </Box>
      </Grid>
    </Grid>
  </>
);
export default SciSupportPage;
