import { DataProvider, RaRecord } from 'react-admin';
import { keyFigures as keyFiguresGbs } from '../importer/GbsBalanceSheetImport';
import log from 'loglevel';
import { EvrBalanceSheetOriginalDataType } from '../importer/types/EvrBalanceSheetOriginalDataType';
import { EvrBalanceSheetType } from '../importer/types/EvrBalanceSheetType';
import { createLogEntry, OAction, OProcess, OReason } from '../logging/loggingUtility';

export interface EvrMappedType extends RaRecord {
  date: Date;
  mappedPositionNumber: string;
  originalMappedDates: EvrOriginalDataMappedType[];
}

export type EvrOriginalDataMappedType = {
  keyfigureMappedName: string;
  values: EvrValueMappedType[];
  sum: number;
};

export type EvrValueMappedType = {
  originalPositionNumber: string;
  originalKeyFigure?: string;
  value: number;
};

const mergeValues = (
  evrOrgDataArray: EvrBalanceSheetOriginalDataType[],
  mappedPositionNumber: string,
) => {
  const findValuesForEvrKeyFigure = (keyFigure: string, originalPositionNumber: string) => {
    return evrOrgDataArray
      .filter((value) => value.keyfigureName === keyFigure)
      .flatMap((evrOrgDataEntry) => evrOrgDataEntry.values)
      .filter((value) => value.originalPositionNumber === originalPositionNumber)
      .map((value) => value.value);
  };

  const originalPositionNumbers = new Set(
    evrOrgDataArray.at(0)?.values.map((value) => value.originalPositionNumber),
  );
  const evrOrgMappedData: EvrOriginalDataMappedType[] = [];

  originalPositionNumbers?.forEach((originalPositionNumber) => {
    const volumeAverage = findValuesForEvrKeyFigure('Volumen Durchschnitt', originalPositionNumber);
    const volumeEffectiveDate = findValuesForEvrKeyFigure(
      'Volumen Stichtag',
      originalPositionNumber,
    );

    const interestIncome = findValuesForEvrKeyFigure('Zinsertrag', originalPositionNumber);
    const interestExpense = findValuesForEvrKeyFigure('Zinsaufwand', originalPositionNumber);
    const currentMargin = findValuesForEvrKeyFigure('Laufende Marge', originalPositionNumber);
    const liquidityContribution = findValuesForEvrKeyFigure(
      'Liquiditätsbeitrag',
      originalPositionNumber,
    );

    keyFiguresGbs.forEach((keyFigureGbs) => {
      let mappedValue = 0;
      const originalValues = new Map<string, number>();

      switch (keyFigureGbs) {
        case 'Buchwert (Durchschnitt)':
          if (mappedPositionNumber !== '100') {
            mappedValue = volumeAverage.reduce((a, b) => a + b, 0);
          } else {
            createLogEntry(
              originalPositionNumber,
              'Volumen Durchschnitt',
              OProcess.Mapping,
              OAction.Changed,
              OReason.ChangedToZero,
            );
          }
          volumeAverage.forEach((value, index) =>
            originalValues.set(`${originalPositionNumber}-Volumen Durchschnitt-${index}`, value),
          );
          break;
        case 'Buchwert (Ultimo)':
          if (mappedPositionNumber !== '100') {
            mappedValue = volumeEffectiveDate.reduce((a, b) => a + b, 0);
          } else {
            createLogEntry(
              originalPositionNumber,
              'Volumen Stichtag',
              OProcess.Mapping,
              OAction.Changed,
              OReason.ChangedToZero,
            );
          }
          volumeEffectiveDate.forEach((value, index) =>
            originalValues.set(`${originalPositionNumber}-Volumen Stichtag-${index}`, value),
          );
          break;
        case 'Nominalvolumen (Durchschnitt)':
          mappedValue = volumeAverage.reduce((a, b) => a + b, 0);
          volumeAverage.forEach((value, index) =>
            originalValues.set(`${originalPositionNumber}-Volumen Durchschnitt-${index}`, value),
          );
          break;
        case 'Zinsüberschuss':
          mappedValue =
            interestIncome.reduce((a, b) => a + b, 0) - interestExpense.reduce((a, b) => a + b, 0);
          interestIncome.forEach((value, index) =>
            originalValues.set(`${originalPositionNumber}-Zinsertrag-${index}`, value),
          );
          interestExpense.forEach((value, index) =>
            originalValues.set(`${originalPositionNumber}-Zinsaufwand-${index}`, value),
          );
          break;
        case 'Margenbeitrag nach Liquidität':
          mappedValue = currentMargin.reduce((a, b) => a + b, 0);
          currentMargin.forEach((value, index) =>
            originalValues.set(`${originalPositionNumber}-Laufende Marge-${index}`, value),
          );
          break;
        case 'Liquiditätsbeitrag':
          mappedValue = liquidityContribution.reduce((a, b) => a + b, 0);
          liquidityContribution.forEach((value, index) =>
            originalValues.set(`${originalPositionNumber}-Liquiditätsbeitrag-${index}`, value),
          );
          break;
        case 'Spreadbeitrag':
          mappedValue =
            currentMargin.reduce((a, b) => a + b, 0) +
            liquidityContribution.reduce((a, b) => a + b, 0);
          currentMargin.forEach((value, index) =>
            originalValues.set(`${originalPositionNumber}-Laufende Marge-${index}`, value),
          );
          liquidityContribution.forEach((value, index) =>
            originalValues.set(`${originalPositionNumber}-Liquiditätsbeitrag-${index}`, value),
          );
          break;
        case 'Strukturbeitrag': {
          // last 2 digits of position number
          const biPo = Number(mappedPositionNumber.slice(1, 3));

          if (!isNaN(biPo)) {
            if (biPo >= 1 && biPo <= 30) {
              mappedValue =
                interestIncome.reduce((a, b) => a + b, 0) -
                interestExpense.reduce((a, b) => a + b, 0) -
                (currentMargin.reduce((a, b) => a + b, 0) +
                  liquidityContribution.reduce((a, b) => a + b, 0));

              interestIncome.forEach((value, index) => {
                originalValues.set(`${originalPositionNumber}-Zinsertrag-${index}`, value);
              });
              interestExpense.forEach((value, index) => {
                originalValues.set(`${originalPositionNumber}-Zinsaufwand-${index}`, value);
              });
              currentMargin.forEach((value, index) => {
                originalValues.set(`${originalPositionNumber}-Laufende Marge-${index}`, value);
              });
              liquidityContribution.forEach((value, index) => {
                originalValues.set(`${originalPositionNumber}-Liquiditätsbeitrag-${index}`, value);
              });
            } else if (biPo >= 31 && biPo <= 60) {
              mappedValue =
                interestIncome.reduce((a, b) => a + b, 0) -
                interestExpense.reduce((a, b) => a + b, 0) +
                currentMargin.reduce((a, b) => a + b, 0) +
                liquidityContribution.reduce((a, b) => a + b, 0);

              interestIncome.forEach((value, index) => {
                originalValues.set(`${originalPositionNumber}-Zinsertrag-${index}`, value);
              });
              interestExpense.forEach((value, index) => {
                originalValues.set(`${originalPositionNumber}-Zinsaufwand-${index}`, value);
              });
              currentMargin.forEach((value, index) => {
                originalValues.set(`${originalPositionNumber}-Laufende Marge-${index}`, value);
              });
              liquidityContribution.forEach((value, index) => {
                originalValues.set(`${originalPositionNumber}-Liquiditätsbeitrag-${index}`, value);
              });
            } else if (mappedPositionNumber === '100') {
              mappedValue =
                interestIncome.reduce((a, b) => a + b, 0) -
                interestExpense.reduce((a, b) => a + b, 0);
              interestIncome.forEach((value, index) =>
                originalValues.set(`${originalPositionNumber}-Zinsertrag-${index}`, value),
              );
              interestExpense.forEach((value, index) =>
                originalValues.set(`${originalPositionNumber}-Zinsaufwand-${index}`, value),
              );
              break;
            } else {
              const message = `BiPo ${biPo} aus gemappten Positionsnummer ${mappedPositionNumber} (ursprüngliche Positionsnummer: ${originalPositionNumber}) kann für die Berechnung des Strukturbeitrags nicht zugeordnet werden und wird mit 0 angenommen.`;
              log.error(message);

              createLogEntry(
                originalPositionNumber,
                'Strukturbeitrag',
                OProcess.Mapping,
                OAction.Discarded,
                OReason.InvalidBiPo,
              );
            }
          } else {
            createLogEntry(
              originalPositionNumber,
              keyFigureGbs,
              OProcess.Merging,
              OAction.Discarded,
              OReason.InvalidPositionNumber,
            );

            const message =
              'Fehler bei Umwandlung der Positionsnummer in eine Bilanzposition ' +
              originalPositionNumber;
            // TODO lyp remove balance sheet position mapping and add it to importer gbs
            // TODO lyp check SWAP
            log.error(message);
          }
          break;
        }
        default: {
          createLogEntry(
            originalPositionNumber,
            keyFigureGbs,
            OProcess.Merging,
            OAction.Discarded,
            OReason.InvalidKeyFigure,
          );

          // TODO lyp add explicit message
          const message = 'Kennzahl von GBS für Mapping nicht zuordenbar: ' + keyFigureGbs;
          log.error(message);
        }
      }

      const originalEvrValues = Array.from(originalValues).map(([originalKeyFigure, value]) => {
        return { originalKeyFigure, originalPositionNumber, value };
      });

      const mappedEvrOrgData = evrOrgMappedData.find(
        (mappedData) => mappedData.keyfigureMappedName === keyFigureGbs,
      );

      if (mappedEvrOrgData) {
        mappedEvrOrgData.values.push(...originalEvrValues);
        mappedEvrOrgData.sum += mappedValue;
      } else {
        evrOrgMappedData.push({
          keyfigureMappedName: keyFigureGbs,
          sum: mappedValue,
          values: originalEvrValues,
        });
      }
    });
  });

  return evrOrgMappedData;
};

export const doMapping = (evrData: EvrBalanceSheetType[]) => {
  const mappedEvrObjArray: EvrMappedType[] = [];

  for (let i = 0; i < evrData.length; i++) {
    const evrElement: EvrBalanceSheetType = evrData[i];
    log.log(evrElement);

    const data = evrElement.originalDates;

    const evrMappedObj: EvrMappedType = {
      id: evrElement.id,
      date: evrElement.date,
      mappedPositionNumber: evrElement.mappedPositionNumber,
      originalMappedDates: mergeValues(data, evrElement.mappedPositionNumber),
    };
    log.log('mapped');
    log.log(evrMappedObj);

    mappedEvrObjArray.push(evrMappedObj);
  }

  return mappedEvrObjArray;
};

const mapEvrBalanceSheet = (dataProvider: DataProvider) => {
  return dataProvider
    .getList<EvrBalanceSheetType>('evrBalanceSheetData', {
      pagination: { page: 1, perPage: 1000000 },
      sort: { field: 'id', order: 'DESC' },
      filter: '',
    })
    .then(({ data }) => {
      if (data !== undefined && data.length !== 0) {
        const mappedEvrObjArray: EvrMappedType[] = doMapping(data);

        dataProvider.clearTableOfResource('evrMappedBalanceSheetData').then(() => {
          dataProvider.createMany('evrMappedBalanceSheetData', { data: mappedEvrObjArray });
        });
        return mappedEvrObjArray;
      } else {
        const message =
          'Keine Daten aus EVR Bilanz Import vorhanden. Bitte erneut ausführen bzw. im Import ggf. Fehler in der Datei beheben.';
        log.error(message);
        throw new Error(message);
      }
    });
};

export default mapEvrBalanceSheet;
