import { DataProvider, RaRecord } from 'react-admin';
import { Row } from 'read-excel-file';
import { v4 as uuidv4 } from 'uuid';
import log from 'loglevel';
import excelColumnName from 'excel-column-name';
import {
  determineTimeFrame,
  getColumnIndexFromHeaderRow,
  getLastDayDateofYearMonth,
  getValueFromCell,
  monthYearDateFormatRegex,
  yearDateFormatRegex,
} from './ImportHelper';

export interface EvrProfitAndLossAccountType extends RaRecord {
  date: Date;
  account: string;
  value: number;
}

const modifyValueBasedOnAccount = (value: number, account: string) => {
  const negatedAccounts = [
    'Zinsaufwand',
    'Provisionsaufwand',
    'sonstiger ordentlicher Aufwand',
    'Verwaltungsaufwand',
    'Personalaufwand',
    'Sachaufwand',
    'Neutraler Aufwand',
    'Gewinnabhängige Steuern',
    'Gewinnausschüttung',
  ];

  if (negatedAccounts.includes(account)) {
    value *= -1;
  }

  return value;
};

export const importEvrProfitAndLossAccountImport = (table: Row[]) => {
  const columnToDateMap: Map<number, Date> = new Map();
  const evrDataArray: EvrProfitAndLossAccountType[] = [];

  const headerRowIndex = 1;
  const beginBodyRowIndex = 3;
  const accountGroupsColumnIndex = getColumnIndexFromHeaderRow(
    table[headerRowIndex],
    'Erfolgskontengruppen',
  );
  const beginDataColumnIndex = getColumnIndexFromHeaderRow(table[headerRowIndex], /^.*Ergebnis.*$/);

  for (let currentRowIndex = 0; currentRowIndex < table.length; currentRowIndex++) {
    const row = table[currentRowIndex];

    let account = '';

    for (let currentColumnIndex = 0; currentColumnIndex < row.length; currentColumnIndex++) {
      const cell = row[currentColumnIndex];

      if (cell === null) {
        break;
      }

      // table header
      if (
        currentRowIndex === headerRowIndex &&
        currentColumnIndex >= beginDataColumnIndex &&
        !cell.toString().includes('%')
      ) {
        // Remove whitespaces
        const cellString = cell.toString().toLowerCase().replace(/\s+/g, '');
        // cut away 'ergebnis' and receive date
        const dateString = cellString.substring(0, cellString.indexOf('e'));

        if (monthYearDateFormatRegex.test(dateString)) {
          const [month, year] = dateString.split('.');
          const date = getLastDayDateofYearMonth(Number(year), Number(month));
          columnToDateMap.set(currentColumnIndex, date);
        } else if (yearDateFormatRegex.test(dateString)) {
          columnToDateMap.set(currentColumnIndex, new Date(Number(dateString), 11));
        } else {
          log.error(cellString, dateString);
          const message = `Das Datum ${dateString} hat das falsche Format: ${cell.toString()} in Spalte ${excelColumnName.intToExcelCol(
            currentColumnIndex + 1,
          )}`;
          log.error(message);
          throw new Error(message);
        }
      }

      // table body
      if (currentRowIndex >= beginBodyRowIndex) {
        if (currentColumnIndex === accountGroupsColumnIndex) {
          account = cell.toString();
        }

        if (currentColumnIndex >= beginDataColumnIndex && columnToDateMap.has(currentColumnIndex)) {
          const value = modifyValueBasedOnAccount(
            getValueFromCell(cell, currentColumnIndex, currentRowIndex),
            account,
          );
          const date = columnToDateMap.get(currentColumnIndex);

          if (date === undefined) {
            // TODO lyp extract error message with row and column in method
            // this should never happen :-)
            throw new Error(
              `Unerwarteter Fehler: Datum konnte nicht aus Header eingelesen werden. Spalte ${excelColumnName.intToExcelCol(
                currentColumnIndex + 1,
              )} Zeile ${currentRowIndex + 1}`,
            );
          }

          evrDataArray.push({ account, id: uuidv4(), value, date });
        }
      }
    }
  }
  return evrDataArray;
};

const startEvrProfitAndLossAccountImport = (table: Row[], dataProvider: DataProvider) => {
  return dataProvider.clearTableOfResource('evrProfitAndLostAccountData').then(() => {
    const importedEvrData: EvrProfitAndLossAccountType[] =
      importEvrProfitAndLossAccountImport(table);
    const timeFrame = determineTimeFrame(importedEvrData);
    dataProvider.createMany('evrProfitAndLostAccountData', { data: importedEvrData });
    return { message: 'Upload successful.', timeFrame };
  });
};

export default startEvrProfitAndLossAccountImport;
