import { Tabs, Box, Tab } from '@mui/material';
import React, { useState, createContext } from 'react';
import SciComparisonTabList from './SciComparisonTabList';
import TabPanel, { tabProps } from './SciComparisonTabPanel';

const SciComparisonTabsBookValues = () => {
  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const GUVContext = createContext(false);

  return (
    <Box>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label='basic tabs example'>
          <Tab label='Buchwert Ultimo' {...tabProps(0)} />
          <Tab label='Buchwert Durchschnitt' {...tabProps(1)} />
          <Tab label='Nominalvolumen Durchschnitt' {...tabProps(2)} />
        </Tabs>
      </Box>
      <GUVContext.Provider value={false}>
        <TabPanel value={value} index={0}>
          <SciComparisonTabList resourceName='bookValueUltimoComparisonData' />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <SciComparisonTabList resourceName='bookValueAverageComparisonData' />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <SciComparisonTabList resourceName='nominalVolumeAverageComparisonData' />
        </TabPanel>
      </GUVContext.Provider>
    </Box>
  );
};

export default SciComparisonTabsBookValues;
