import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { Box, CircularProgress } from '@mui/material';
import log from 'loglevel';
import { Button } from 'react-admin';

const Transition = React.forwardRef(
  (
    props: TransitionProps & {
      children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
  ) => <Slide direction='up' ref={ref} {...props} />,
);
Transition.displayName = 'Transition';

export const HtmlGetDialog = (prop: { source: string; label: string; buttonLabel: string }) => {
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({});

  const handleClickOpen = () => {
    fetch(prop.source)
      .then((response) => {
        if (response.status >= 400) {
          throw new Error('Server antwortet mit einem Fehler!');
        }
        return response.text();
      })
      .then((data) => {
        setData(data);
        setLoading(false);
      })
      .catch((e) => {
        log.error(`Fehler beim Abrufen der Daten: ${e}`);
      });
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Button
        sx={{ display: 'block', textAlign: 'left' }}
        variant='text'
        onClick={handleClickOpen}
        label={prop.buttonLabel}
      />
      <Dialog fullScreen open={open} onClose={handleClose} TransitionComponent={Transition}>
        <AppBar sx={{ position: 'relative' }}>
          <Toolbar>
            <IconButton edge='start' color='inherit' onClick={handleClose} aria-label='close'>
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant='h6' component='div'>
              {prop.label}
            </Typography>
          </Toolbar>
        </AppBar>
        <Box>
          {loading ? (
            <CircularProgress />
          ) : (
            <div dangerouslySetInnerHTML={{ __html: '' + data }}></div>
          )}
        </Box>
      </Dialog>
    </>
  );
};
