import * as React from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import LoadingButton from '@mui/lab/LoadingButton';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { Checkbox, CircularProgress, FormControlLabel, FormGroup, Stack } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import Link from '@mui/material/Link';
import {
  Button,
  SimpleForm,
  TextInput,
  useDataProvider,
  useNotify,
  useTranslate,
} from 'react-admin';
import readXlsxFile from 'read-excel-file/web-worker';
import Dropzone, { FileRejection } from 'react-dropzone';
import startGbsBalanceSheetImporter from '../../entities/importer/GbsBalanceSheetImport';
import startEvrBalanceSheetImporter from '../../entities/importer/EvrBalanceSheetImport';
import {
  ActionType,
  Deviation,
  ImportWizardContext,
  useWizardContext,
} from '../../contexts/importWizardContext/ImportWizardContextProvider';
import { useState, useEffect } from 'react';
import mapEvrBalanceSheet from '../../entities/merge/MapEvrBalanaceSheet';
import startGbsProfitAndLossAccountImport from '../../entities/importer/GbsProfitAndLossAccountImport';
import startEvrProfitAndLossAccountImport from '../../entities/importer/EvrProfitAndLossAccountImport';
import mergeProfitAndLossAccount from '../../entities/merge/MergeProfitAndLossAccount';
import { HtmlGetDialog } from '../../fields/HtmlGetDialog';
import log from 'loglevel';
import { Link as RouterLink } from 'react-router-dom';

import { mergeBalanceSheet } from '../../entities/merge/MergeBalanceSheet';
import { clearLog } from '../../entities/logging/loggingUtility';
import { TimeFrame } from '../../entities/importer/types/TimeFrameType';
import 'moment/locale/de';
import CustomDatePicker, { CustomDatePickerType } from '../../fields/CustomDatePicker';

export enum AnalysisType {
  InterestResult = 'InterestResultAnalysis',
  ProfitAndLoss = 'ProfitAndLossAnalysis',
}

export enum ImportType {
  Gbs = 'GBS',
  Evr = 'EVR',
}

const GbsBetrachtungshorizont = () => {
  const {
    interestResultAnalysisActive,
    setInterestResultAnalysisActive,
    profitAndLossAnalysisActive,
    setProfitAndLossAnalysisActive,
  } = useWizardContext();
  return (
    <FormGroup>
      <FormControlLabel
        control={
          <Checkbox
            checked={interestResultAnalysisActive}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setInterestResultAnalysisActive(event.target.checked);
            }}
          />
        }
        label='Volumina- und Zinsergebnis-Abweichungsanalyse'
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={profitAndLossAnalysisActive}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setProfitAndLossAnalysisActive(event.target.checked);
            }}
          />
        }
        label='GuV-Abweichungsanalyse'
      />
    </FormGroup>
  );
};

const FileUploadSuccessful = () => (
  <Box mt={2} mb={2} sx={{ display: 'flex' }}>
    <CheckCircleIcon sx={{ marginRight: '10px' }} />
    <Typography variant='body1'>Die Datei wurde erfolgreich hochgeladen.</Typography>
  </Box>
);

const GbsImport = () => {
  const dataProvider = useDataProvider();
  const [interesResultFileUploaded, setInteresResultFileUploaded] = useState(false);
  const [profitAndLossFileUploaded, setProfitAndLossFileUploaded] = useState(false);

  const {
    dispatch,
    interestResultAnalysisActive,
    profitAndLossAnalysisActive,
    interestResultGbsUploaded,
    profitAndLossGbsUploaded,
    setInterestResultTimeFrame,
    setProfitAndLossTimeFrame,
    setInterestResultGbsUploaded,
    setProfitAndLossGbsUploaded,
    setDateRange,
  } = useWizardContext();

  const GbsImportInterestResultAnalysis = () => {
    const [loading, setLoading] = useState(false);
    const notify = useNotify();
    const onDropHandler = (acceptedFiles: File[]) => {
      readXlsxFile(acceptedFiles[0])
        .then((rows) => {
          setLoading(true);
          if (!profitAndLossFileUploaded) {
            clearLog();
          }
          return startGbsBalanceSheetImporter(rows, dataProvider);
        })
        .then(({ timeFrame, startDate, endDate }) => {
          setLoading(false);
          setInteresResultFileUploaded(true);
          setInterestResultGbsUploaded(true);
          setInterestResultTimeFrame(timeFrame);
          setDateRange((prevDateRange) => ({
            ...prevDateRange,
            selectedStartDate: startDate,
            startDate: startDate,
            selectedEndDate: endDate,
            endDate: endDate,
            confirmed: false,
          }));
          dispatch({
            type: ActionType.Import,
            importType: ImportType.Gbs,
            timeFrame,
            analysisType: AnalysisType.InterestResult,
            interestResultAnalysisActive,
            profitAndLossAnalysisActive,
          });
        })
        .catch((error) => {
          notify(error.message, { type: 'error' });
        });
    };

    const onDropRejectHandler = (fileRejections: FileRejection[]) => {
      log.log('Fehler beim Uploadhandling' + fileRejections[0]);
      notify('Falsches Dateiformat. Derzeit wird nur ".xlsx" unterstützt.', { type: 'error' });
    };

    return (
      <>
        {interesResultFileUploaded ? (
          <FileUploadSuccessful />
        ) : (
          <Stack direction='row' alignItems='center' gap={1}>
            <Box mt={2} mb={2} p={1} sx={{ backgroundColor: 'lightgrey', borderRadius: '5px' }}>
              <Dropzone
                onDropAccepted={onDropHandler}
                onDropRejected={onDropRejectHandler}
                maxFiles={1}
                accept={'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}>
                {({ getRootProps, getInputProps }) => (
                  <Box {...getRootProps()}>
                    <input {...getInputProps()} />
                    <Stack direction='row' alignItems='center' gap={1}>
                      <UploadFileIcon sx={{ marginRight: '10px' }} />
                      {loading ? (
                        <CircularProgress />
                      ) : (
                        <Typography variant='body1'>
                          {interestResultGbsUploaded ? (
                            <>
                              Zum Überschreiben der importierten Datei klicken Sie bitte hier oder
                              ziehen Sie die exportierte GBS Bilanz-Datei hinein.
                            </>
                          ) : (
                            <>
                              Zum Importieren der Daten klicken Sie bitte hier oder ziehen Sie die
                              exportierte GBS Bilanz-Datei hinein.
                            </>
                          )}
                        </Typography>
                      )}
                    </Stack>
                  </Box>
                )}
              </Dropzone>
            </Box>
            <HtmlGetDialog
              source='https://api.ppi-cloud.de/page.php?page=25'
              label='Export GBS Bilanz'
              buttonLabel='Hilfe'
            />
          </Stack>
        )}
      </>
    );
  };

  const GbsImportProfitAndLossAnalysis = () => {
    const notify = useNotify();
    const [loading, setLoading] = useState(false);

    const onDropHandler = (acceptedFiles: File[]) => {
      readXlsxFile(acceptedFiles[0])
        .then((rows) => {
          setLoading(true);
          if (!interesResultFileUploaded) {
            clearLog();
          }
          return startGbsProfitAndLossAccountImport(rows, dataProvider);
        })
        .then(({ timeFrame, startDate, endDate }) => {
          setLoading(false);
          setProfitAndLossTimeFrame(timeFrame);
          setProfitAndLossFileUploaded(true);
          setProfitAndLossGbsUploaded(true);
          setDateRange((prevDateRange) => ({
            ...prevDateRange,
            selectedStartDate: startDate,
            startDate: startDate,
            selectedEndDate: endDate,
            endDate: endDate,
            confirmed: false,
          }));
          dispatch({
            type: ActionType.Import,
            importType: ImportType.Gbs,
            timeFrame,
            analysisType: AnalysisType.ProfitAndLoss,
            interestResultAnalysisActive,
            profitAndLossAnalysisActive,
          });
        })
        .catch((error) => {
          notify(error.message, { type: 'error' });
        });
    };

    const onDropRejectHandler = (fileRejections: FileRejection[]) => {
      log.log('Fehler beim Uploadhandling' + fileRejections[0]);
      notify('Falsches Dateiformat. Derzeit wird nur ".xlsx" unterstützt.', { type: 'error' });
    };

    return (
      <>
        {profitAndLossFileUploaded ? (
          <FileUploadSuccessful />
        ) : (
          <Stack direction='row' alignItems='center' gap={1}>
            <Box mt={2} mb={2} p={1} sx={{ backgroundColor: 'lightgrey', borderRadius: '5px' }}>
              <Dropzone
                onDropAccepted={onDropHandler}
                onDropRejected={onDropRejectHandler}
                maxFiles={1}
                accept={'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}>
                {({ getRootProps, getInputProps }) => (
                  <Box {...getRootProps()}>
                    <input {...getInputProps()} />
                    <Stack direction='row' alignItems='center' gap={1}>
                      <UploadFileIcon sx={{ marginRight: '10px' }} />
                      {loading ? (
                        <CircularProgress />
                      ) : (
                        <Typography variant='body1'>
                          {profitAndLossGbsUploaded ? (
                            <>
                              Zum Überschreiben der importierten Datei klicken Sie bitte hier oder
                              ziehen Sie die exportierte GBS GuV-Datei hinein.
                            </>
                          ) : (
                            <>
                              Zum Importieren der Daten klicken Sie bitte hier oder ziehen Sie die
                              exportierte GBS GuV-Datei hinein.
                            </>
                          )}
                        </Typography>
                      )}
                    </Stack>
                  </Box>
                )}
              </Dropzone>
            </Box>
            <HtmlGetDialog
              source='https://api.ppi-cloud.de/page.php?page=12'
              label='Export GBS GuV'
              buttonLabel='Hilfe'
            />
          </Stack>
        )}
      </>
    );
  };

  return (
    <>
      {interestResultAnalysisActive ? <GbsImportInterestResultAnalysis /> : <></>}
      {profitAndLossAnalysisActive ? <GbsImportProfitAndLossAnalysis /> : <></>}
    </>
  );
};

const EvrImport = () => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const [interesResultFileUploaded, setInterestResultFileUploaded] = useState(false);
  const [profitAndLossFileUploaded, setProfitAndLossFileUploaded] = useState(false);

  const {
    dispatch,
    interestResultAnalysisActive,
    profitAndLossAnalysisActive,
    interestResultTimeFrame,
    profitAndLossTimeFrame,
    interestResultEvrUploaded,
    profitAndLossEvrUploaded,
    setInterestResultTimeFrame,
    setProfitAndLossTimeFrame,
    setInterestResultEvrUploaded,
    setProfitAndLossEvrUploaded,
  } = useWizardContext();

  const EvrImportInterestResultAnalysis = () => {
    const [loading, setLoading] = useState(false);
    const onDropHandler = (acceptedFiles: File[]) => {
      readXlsxFile(acceptedFiles[0])
        .then((rows) => {
          setLoading(true);
          return startEvrBalanceSheetImporter(rows, dataProvider);
        })
        .then(({ timeFrame }) => {
          setLoading(false);
          setInterestResultEvrUploaded(true);
          const isDifferentTimeFrame =
            interestResultTimeFrame !== TimeFrame.Empty && interestResultTimeFrame !== timeFrame;
          dispatch({
            type: ActionType.Import,
            importType: ImportType.Evr,
            timeFrame: isDifferentTimeFrame ? TimeFrame.Empty : timeFrame,
            analysisType: AnalysisType.InterestResult,
            interestResultAnalysisActive,
            profitAndLossAnalysisActive,
          });
          if (isDifferentTimeFrame) {
            throw new Error(
              'Die Upload-Dateien enthalten unterschiedliche Zeitraster. Ein Vergleich ist daher nicht möglich. Bitte importieren Sie die Daten entweder nur auf Monats- oder nur auf Jahresbasis.',
            );
          }
          setInterestResultTimeFrame(timeFrame);
          setInterestResultFileUploaded(true);
        })
        .catch((error) => {
          notify(error.message, { type: 'error' });
        });
    };

    const onDropRejectHandler = (fileRejections: FileRejection[]) => {
      log.log('Fehler beim Uploadhandling' + fileRejections[0]);
      notify('Falsches Dateiformat. Derzeit wird nur ".xlsx" unterstützt.', { type: 'error' });
    };

    return (
      <>
        {interesResultFileUploaded ? (
          <FileUploadSuccessful />
        ) : (
          <Stack direction='row' alignItems='center' gap={1}>
            <Box mt={2} mb={2} p={1} sx={{ backgroundColor: 'lightgrey', borderRadius: '5px' }}>
              <Dropzone
                onDropAccepted={onDropHandler}
                onDropRejected={onDropRejectHandler}
                maxFiles={1}
                accept={'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}>
                {({ getRootProps, getInputProps }) => (
                  <Box {...getRootProps()}>
                    <input {...getInputProps()} />
                    <Stack direction='row' alignItems='center' gap={1}>
                      <UploadFileIcon sx={{ marginRight: '10px' }} />
                      {loading ? (
                        <CircularProgress />
                      ) : (
                        <Typography variant='body1'>
                          {interestResultEvrUploaded ? (
                            <>
                              Zum Überschreiben der importierten Datei klicken Sie bitte hier oder
                              ziehen Sie die exportierte EVR Detail-Ergebnissicht hinein.
                            </>
                          ) : (
                            <>
                              Zum Importieren der Daten klicken Sie bitte hier oder ziehen Sie die
                              exportierte Datei der EVR Detail-Ergebnissicht hinein.
                            </>
                          )}
                        </Typography>
                      )}
                    </Stack>
                  </Box>
                )}
              </Dropzone>
            </Box>

            <HtmlGetDialog
              source='https://api.ppi-cloud.de/page.php?page=27'
              label='Export EVR Detail-Ergebnissicht'
              buttonLabel='Hilfe'
            />
          </Stack>
        )}
      </>
    );
  };

  const EvrImportProfitAndLossAnalysis = () => {
    const [loading, setLoading] = useState(false);
    const onDropHandler = (acceptedFiles: File[]) => {
      readXlsxFile(acceptedFiles[0])
        .then((rows) => {
          setLoading(true);
          return startEvrProfitAndLossAccountImport(rows, dataProvider);
        })
        .then(({ timeFrame }) => {
          setLoading(false);
          setProfitAndLossEvrUploaded(true);

          const isDifferentTimeFrame =
            profitAndLossTimeFrame !== TimeFrame.Empty && profitAndLossTimeFrame !== timeFrame;
          dispatch({
            type: ActionType.Import,
            importType: ImportType.Evr,
            timeFrame: isDifferentTimeFrame ? TimeFrame.Empty : timeFrame,
            analysisType: AnalysisType.ProfitAndLoss,
            interestResultAnalysisActive,
            profitAndLossAnalysisActive,
          });
          if (isDifferentTimeFrame) {
            throw new Error(
              'Die Upload-Dateien enthalten unterschiedliche Zeitraster. Ein Vergleich ist daher nicht möglich. Bitte importieren Sie die Daten entweder nur auf Monats- oder nur auf Jahresbasis.',
            );
          }
          setProfitAndLossTimeFrame(timeFrame);
          setProfitAndLossFileUploaded(true);
        })
        .catch((error) => {
          notify(error.message, { type: 'error' });
        });
    };

    const onDropRejectHandler = (fileRejections: FileRejection[]) => {
      log.log('Fehler beim Uploadhandling' + fileRejections[0]);
      notify('Falsches Dateiformat. Derzeit wird nur ".xlsx" unterstützt.', { type: 'error' });
    };

    return (
      <>
        {profitAndLossFileUploaded ? (
          <FileUploadSuccessful />
        ) : (
          <Stack direction='row' alignItems='center' gap={1}>
            <Box mt={2} mb={2} p={1} sx={{ backgroundColor: 'lightgrey', borderRadius: '5px' }}>
              <Dropzone
                onDropAccepted={onDropHandler}
                onDropRejected={onDropRejectHandler}
                maxFiles={1}
                accept={'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}>
                {({ getRootProps, getInputProps }) => (
                  <Box {...getRootProps()}>
                    <input {...getInputProps()} />
                    <Stack direction='row' alignItems='center' gap={1}>
                      <UploadFileIcon sx={{ marginRight: '10px' }} />
                      {loading ? (
                        <CircularProgress />
                      ) : (
                        <Typography variant='body1'>
                          {profitAndLossEvrUploaded ? (
                            <>
                              Zum Überschreiben der importierten Datei klicken Sie bitte hier oder
                              ziehen Sie die exportierte EVR Erfolgsspannenrechnung hinein.
                            </>
                          ) : (
                            <>
                              Zum Importieren der Daten klicken Sie bitte hier oder ziehen Sie die
                              exportierte Datei der EVR Erfolgsspannenrechnung hinein.
                            </>
                          )}
                        </Typography>
                      )}
                    </Stack>
                  </Box>
                )}
              </Dropzone>
            </Box>
            <HtmlGetDialog
              source='https://api.ppi-cloud.de/page.php?page=26'
              label='Export EVR Erfolgsspannenrechnung'
              buttonLabel='Hilfe'
            />
          </Stack>
        )}
      </>
    );
  };

  return (
    <>
      {interestResultAnalysisActive ? <EvrImportInterestResultAnalysis /> : <></>}
      {profitAndLossAnalysisActive ? <EvrImportProfitAndLossAnalysis /> : <></>}
    </>
  );
};

const GbsAuswertungszeitraum = () => {
  const { deviation, setDeviation } = useWizardContext();
  return (
    <>
      <TextInput
        sx={{ width: 170, mt: 3, mr: 1, mb: 0 }}
        source='absoluteDeviation'
        label='Abweichung absolut (€)'
        variant='outlined'
        type='number'
        defaultValue={deviation.confirmed ? deviation.absolute : deviation.previousAbsolute}
        onBlur={(event) => {
          setDeviation((prevDeviation: Deviation) => ({
            ...prevDeviation,
            absolute: event.target.value,
            confirmed: false,
          }));
        }}
      />
      <TextInput
        sx={{ width: 170, mt: 3, mr: 1, mb: 0 }}
        source='relativeDeviation'
        label='Abweichung relativ (%)'
        variant='outlined'
        type='number'
        required
        defaultValue={deviation.confirmed ? deviation.relative : deviation.previousRelative}
        onBlur={(event) => {
          setDeviation((prevDeviation: Deviation) => ({
            ...prevDeviation,
            relative: event.target.value,
            confirmed: false,
          }));
        }}
      />
      <CustomDatePicker label='Startdatum' dateType={CustomDatePickerType.START_DATE} global />
      <CustomDatePicker label='Enddatum' dateType={CustomDatePickerType.END_DATE} global />
    </>
  );
};

const GbsAnalysieren = () => {
  const { setDeviation, setDateRange, interestResultAnalysisActive, profitAndLossAnalysisActive } =
    useWizardContext();
  const notify = useNotify();
  const dataProvider = useDataProvider();
  const translate = useTranslate();
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  useEffect(() => {
    setDeviation((prevDeviation) => ({
      ...prevDeviation,
      previousAbsolute: success ? prevDeviation.absolute : prevDeviation.previousAbsolute,
      previousRelative: success ? prevDeviation.relative : prevDeviation.previousRelative,
      confirmed: success,
    }));
    setDateRange((prevDateRange) => ({
      ...prevDateRange,
      confirmed: success,
    }));
  }, [success, setDeviation, setDateRange]);

  const handleButtonClick = () => {
    if (!loading) {
      setSuccess(false);
      setLoading(true);

      if (profitAndLossAnalysisActive) {
        mergeProfitAndLossAccount(dataProvider).then(
          ({ message }) => {
            setSuccess(true);
            setLoading(false);
            notify(message, { type: 'success' });
          },
          (error) => {
            setSuccess(false);
            setLoading(false);
            notify(error.message, { type: 'error' });
          },
        );
      }

      if (interestResultAnalysisActive) {
        mapEvrBalanceSheet(dataProvider)
          .then((value) => {
            return mergeBalanceSheet(dataProvider, value);
          })
          .then(({ message }) => {
            setSuccess(true);
            setLoading(false);
            notify(message, { type: 'success' });
          })
          .catch((error) => {
            setSuccess(false);
            setLoading(false);
            notify(error.message, { type: 'error' });
          });
      }
    }
  };

  return (
    <>
      {success ? (
        <Button variant='contained' sx={{ mt: 1, mr: 1, backgroundColor: '#2e7d32 !important' }}>
          <Link
            component={RouterLink}
            to='/compare'
            style={{
              textDecoration: 'inherit',
              color: 'inherit',
              fontSize: 'inherit',
              textAlign: 'inherit',
            }}>
            {translate('ra.action.compare')}
          </Link>
        </Button>
      ) : (
        <LoadingButton
          sx={{ mt: 1, mr: 1 }}
          loading={loading}
          variant='contained'
          onClick={handleButtonClick}>
          {translate('ra.action.analyse')}
        </LoadingButton>
      )}
    </>
  );
};

const SciImportStepper = () => {
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <SimpleForm toolbar={false}>
      <Box sx={{ maxWidth: 400 }}>
        <ImportWizardContext.Consumer>
          {(value) => (
            <>
              <Stepper activeStep={activeStep} orientation='vertical'>
                {value.steps.map((step, index) => (
                  <Step key={step.label}>
                    <StepLabel>{step.label}</StepLabel>
                    <StepContent sx={{ '& .MuiButtonBase-root': { marginTop: '0px' } }}>
                      <Typography>{step.description}</Typography>
                      {index === 0 ? <GbsBetrachtungshorizont /> : null}
                      {index === 1 ? <GbsImport /> : null}
                      {index === 2 ? <EvrImport /> : null}
                      {index === 3 ? <GbsAuswertungszeitraum /> : null}
                      <Box sx={{ mb: 2 }}>
                        <Button
                          disabled={index === 0}
                          onClick={handleBack}
                          sx={{ mt: 1, mr: 1 }}
                          label='ra-form-layout.action.previous'
                        />
                        {index === value.steps.length - 1 ? (
                          <GbsAnalysieren />
                        ) : (
                          <Button
                            variant='contained'
                            onClick={handleNext}
                            disabled={!step.valid}
                            sx={{ mt: 1, mr: 1 }}
                            label='ra-form-layout.action.next'
                          />
                        )}
                      </Box>
                    </StepContent>
                  </Step>
                ))}
              </Stepper>
              {activeStep === value.steps.length && (
                <Paper square elevation={0} sx={{ p: 3 }}>
                  <Typography>All steps completed - you&apos;re finished</Typography>
                  <Button
                    onClick={handleReset}
                    sx={{ mt: 1, mr: 1 }}
                    label='ra-form-layout.action.reset'
                  />
                </Paper>
              )}
            </>
          )}
        </ImportWizardContext.Consumer>
      </Box>
    </SimpleForm>
  );
};

export default SciImportStepper;
