import { Box, TableCell, TableRow, TextField } from '@mui/material';
import { useDataProvider, useListContext, useRecordContext } from 'react-admin';
import { GbsAbweichungsgruendeDetails } from '../../entities/abweichungsgruende/GbsAbweichungsgruende';
import ComparisonBaseDataType from '../../entities/comparison/ComparisonBaseDataType';
import ComparsionProfitAndLossAccountDataType from '../../entities/comparison/ComparsionProfitAndLossAccountDataType';
import React, { useContext } from 'react';
import log from 'loglevel';
import { GUVContext } from './SciComparisonView';

const GbsCompareTable = () => {
  const record = useRecordContext<
    ComparisonBaseDataType | ComparsionProfitAndLossAccountDataType
  >();
  const dataPoints = record?.values;

  return (
    <>
      <TableRow>
        <TableCell sx={{ fontWeight: 'bold' }}>EVR</TableCell>
        <TableCell>{dataPoints[0].evrData.positionNumber}</TableCell>
        <TableCell />
        {dataPoints?.map((dataPoint) => (
          <TableCell key={dataPoint.gbsData[0].id} align='right'>
            {formatNumber(dataPoint.evrData.value)}
          </TableCell>
        ))}
      </TableRow>

      {dataPoints?.at(0)?.gbsData.map((gbsRow, index) => {
        return (
          <TableRow key={`table-row-${index}`}>
            <TableCell>GBS</TableCell>
            <TableCell>{gbsRow.positionNumber}</TableCell>
            <TableCell />
            {dataPoints.map((dataPoint) => {
              const { value } = dataPoint.gbsData[index];
              return (
                <TableCell key={`${index}-${dataPoint.gbsData[index].id}`} align='right'>
                  {formatNumber(value)}
                </TableCell>
              );
            })}
          </TableRow>
        );
      })}

      <TableRow>
        <TableCell sx={{ fontWeight: 'bold' }}>Summe GBS</TableCell>
        <TableCell />
        <TableCell />
        {dataPoints.map((dataPoint, index) => {
          const sum = dataPoint.gbsData.reduce(
            (partialSum, currentEntry) => partialSum + currentEntry.value,
            0,
          );
          return (
            <TableCell key={`gbs-sum-${sum}-${index}`} align='right'>
              {formatNumber(sum)}
            </TableCell>
          );
        })}
      </TableRow>

      <TableRow>
        <TableCell>Abweichung</TableCell>
        <TableCell />
        <TableCell />
        {dataPoints.map((dataPoint, index) => (
          <TableCell key={`deviation-${index}`} align='right'>
            {formatNumber(dataPoint.value * 100)}%
          </TableCell>
        ))}
      </TableRow>
    </>
  );
};

const CommentField = () => {
  const dataProvider = useDataProvider();
  const listContext = useListContext();
  const record = useRecordContext<
    ComparisonBaseDataType | ComparsionProfitAndLossAccountDataType
  >();

  const updateComment = (event: React.ChangeEvent<HTMLInputElement>) => {
    record.comment = event.target.value;
    dataProvider
      .update(listContext.resource, {
        id: record.id,
        data: {
          comment: event.target.value,
        },
        previousData: record,
      })
      .catch((error) => {
        log.error(error);
      });
  };

  return (
    <TextField
      multiline={true}
      fullWidth={true}
      label='Kommentar'
      defaultValue={record.comment}
      onChange={updateComment}
      variant='outlined'
    />
  );
};

const SciComparisonTabExpandPanel = () => {
  const record = useRecordContext<
    ComparisonBaseDataType | ComparsionProfitAndLossAccountDataType
  >();
  const guvContext = useContext(GUVContext);
  return (
    <>
      <GbsCompareTable />
      <TableRow>
        <TableCell colSpan={record.values.length + 3} sx={{ padding: '6px 0px' }}>
          <Box
            sx={{
              maxWidth: guvContext ? '86vw' : '82vw',
              display: 'flex',
              flexDirection: 'row',
              position: 'sticky',
              left: 0,
            }}>
            <Box
              sx={{ minWidth: guvContext ? '43vw' : '41vw', display: 'flex', marginRight: '8px' }}>
              <GbsAbweichungsgruendeDetails gbspos={record.positionNumber} tab={record.name} />
            </Box>
            <CommentField />
          </Box>
        </TableCell>
      </TableRow>
    </>
  );
};

const formatNumber = (value: number) => {
  return Number(value.toFixed(2)).toLocaleString('de', { minimumFractionDigits: 2 });
};

export default SciComparisonTabExpandPanel;
