import React, { useEffect, useState } from 'react';
import { useNotify } from 'react-admin';
import { Alert, AlertTitle, CircularProgress, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { HtmlGetDialog } from '../../fields/HtmlGetDialog';
import DeviationReasonType from './DeviationReasonType';
import log from 'loglevel';

const DeviationReasonsDetailsList = (prop: { gbspos: string; tab: string }) => {
  const notify = useNotify();
  const [loading, setLoading] = useState(true);
  const [deviationReasonArray, setDeviationReasonArray] = useState<DeviationReasonType[]>([]);

  useEffect(() => {
    fetch('./deviationReasons/recommendedActionsMap.jsn', {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
    })
      .then((response) => {
        if (!response.ok) {
          notify('Die Abweichungsgründe konnten nicht geladen werden.', { type: 'error' });
          return <></>;
        }
        return response.json();
      })
      .then((json) => {
        const filteredArray: DeviationReasonType[] = [];
        (json as DeviationReasonType[])
          .filter(({ tab, positionNumber }) => prop.tab === tab && prop.gbspos === positionNumber)
          .forEach((entry) => {
            if (
              filteredArray.find(
                (reason) =>
                  entry.pageId === reason.pageId &&
                  entry.deviationReason === reason.deviationReason &&
                  entry.positionNumber === reason.positionNumber,
              ) === undefined
            ) {
              filteredArray.push(entry);
            }
          });
        setDeviationReasonArray(filteredArray);
        setLoading(false);
      })
      .catch((error) => log.error(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (deviationReasonArray.map === undefined) {
    return (
      <Typography variant='body1'>Die Abweichungsgründe konnten nicht geladen werden.</Typography>
    );
  } else {
    return loading ? (
      <CircularProgress />
    ) : (
      <>
        {deviationReasonArray.map(({ pageId, deviationReason, positionNumber }, index) => (
          <HtmlGetDialog
            key={`${positionNumber}-${pageId}-${index}`}
            label={deviationReason as string}
            buttonLabel={deviationReason as string}
            source={'./deviationReasons/htmlFiles/' + pageId + '.html'}
          />
        ))}
      </>
    );
  }
};

// Anzeige von Abweichungsgründen in der Detailansicht
export const GbsAbweichungsgruendeDetails = (prop: { gbspos: string; tab: string }) => {
  return (
    <Alert severity='info' sx={{ minHeight: '169px', paddingTop: '16px' }}>
      <AlertTitle>Mögliche Abweichungsgründe</AlertTitle>
      {prop.tab !== '' ? <>für {prop.tab} </> : <></>}
      in der Bilanzposition <strong>{prop.gbspos}</strong>
      <Box mt={2}>
        <DeviationReasonsDetailsList gbspos={prop.gbspos} tab={prop.tab} />
      </Box>
    </Alert>
  );
};
