import { Tabs, Box, Tab } from '@mui/material';
import React, { useState } from 'react';

import SciComparisonTabList from './SciComparisonTabList';
import TabPanel, { tabProps } from './SciComparisonTabPanel';
import { GUVContext } from './SciComparisonView';

const SciComparisonTabsInterestResult = () => {
  const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box>
      <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={value} onChange={handleChange} aria-label='basic tabs example'>
          <Tab label='Zinsergebnis' {...tabProps(0)} />
          <Tab label='Margenbeitrag' {...tabProps(1)} />
          <Tab label='Liquiditätsbeitrag' {...tabProps(2)} />
          <Tab label='Creditspread' {...tabProps(3)} />
          <Tab label='Strukturbeitrag' {...tabProps(4)} />
        </Tabs>
      </Box>
      <GUVContext.Provider value={false}>
        <TabPanel value={value} index={0}>
          <SciComparisonTabList resourceName='externalInterestResultComparisonData' />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <SciComparisonTabList resourceName='marginContributionComparisonData' />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <SciComparisonTabList resourceName='liquidityContributionComparisonData' />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <SciComparisonTabList resourceName='creditSpreadComparisonData' />
        </TabPanel>
        <TabPanel value={value} index={4}>
          <SciComparisonTabList resourceName='structuralContributionComparisonData' />
        </TabPanel>
      </GUVContext.Provider>
    </Box>
  );
};

export default SciComparisonTabsInterestResult;
