import NewDexieDataProvider from './NewDexieDataProvider';

const DEXIE_DATABASE_NAME = 'SCI_DEXIE';
const DEXIE_DATABASE_VERSION = 2.6;
const DEXIE_DATABASE_STORES = {
  events: 'date, author, resource, action, payload',
  externalInterestResultComparisonData:
    'id, name,deviationReasonId,positionNumber,balanceSheetPosition,values',
  marginContributionComparisonData:
    'id, name,deviationReasonId,positionNumber,balanceSheetPosition,values',
  liquidityContributionComparisonData:
    'id, name,deviationReasonId,positionNumber,balanceSheetPosition,values',
  creditSpreadComparisonData:
    'id, name,deviationReasonId,positionNumber,balanceSheetPosition,values',
  structuralContributionComparisonData:
    'id, name,deviationReasonId,positionNumber,balanceSheetPosition,values',
  nominalVolumeAverageComparisonData:
    'id, name,deviationReasonId,positionNumber,balanceSheetPosition,values',
  bookValueAverageComparisonData:
    'id, name,deviationReasonId,positionNumber,balanceSheetPosition,values',
  bookValueUltimoComparisonData:
    'id, name,deviationReasonId,positionNumber,balanceSheetPosition,values',
  gbsBalanceSheetData: 'id, date, positionNumber, balanceSheetPositionName, keyFigureName, value',
  evrBalanceSheetData: 'id, date, mappedPositionNumber, originalDates',
  evrMappedBalanceSheetData: 'id, date, mappedPositionNumber, originalMappedDates',
  evrProfitAndLostAccountData: 'id, date, account, value',
  gbsProfitAndLostAccountData: 'id, date, account, value, accountName',
  profitAndLossComparsionData: 'id, date, deviationReasonId, name, positionNumber, values',
  loggingData: 'id, positionNumber, keyFigure, process, action, reason, timestamp',
  errorData: 'id, errorMessage, timestamp',
};

const dexieDataProvider = NewDexieDataProvider({
  databaseName: DEXIE_DATABASE_NAME,
  databaseVersion: DEXIE_DATABASE_VERSION,
  databaseStores: DEXIE_DATABASE_STORES,
});

const SciDexieDataProvider = dexieDataProvider;

export default SciDexieDataProvider;
