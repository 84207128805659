import { createContext, Reducer, useContext, useEffect, useReducer, useState } from 'react';
import { TimeFrame } from '../../entities/importer/types/TimeFrameType';
import { AnalysisType, ImportType } from '../../pages/ComparisonPage/SciImportStepper';

export interface Deviation {
  previousAbsolute: string | null;
  previousRelative: string;
  absolute: string | null;
  relative: string;
  confirmed: boolean;
}

export interface DateRange {
  selectedStartDate: Date | undefined;
  startDate: Date | undefined;
  selectedEndDate: Date | undefined;
  endDate: Date | undefined;
}

type ImportWizardContextType = {
  steps: StepState[];
  dispatch: (value: StepperAction) => void;
  profitAndLossTimeFrame: TimeFrame;
  interestResultTimeFrame: TimeFrame;
  interestResultAnalysisActive: boolean;
  profitAndLossAnalysisActive: boolean;
  profitAndLossGbsUploaded: boolean;
  profitAndLossEvrUploaded: boolean;
  interestResultGbsUploaded: boolean;
  interestResultEvrUploaded: boolean;
  deviation: Deviation;
  dateRange: DateRange;
  setInterestResultAnalysisActive: (active: boolean) => void;
  setProfitAndLossAnalysisActive: (active: boolean) => void;
  setProfitAndLossGbsUploaded: (uploaded: boolean) => void;
  setProfitAndLossEvrUploaded: (uploaded: boolean) => void;
  setInterestResultGbsUploaded: (uploaded: boolean) => void;
  setInterestResultEvrUploaded: (uploaded: boolean) => void;
  setInterestResultTimeFrame: (timeFrame: TimeFrame) => void;
  setProfitAndLossTimeFrame: (timeFrame: TimeFrame) => void;
  setDeviation: (cb: (prevDeviation: Deviation) => Deviation) => void;
  setDateRange: (cb: (prevDateRange: DateRange) => DateRange) => void;
};
interface StepStateAnalysis {
  timeFrame: TimeFrame;
}
interface StepState {
  label: string;
  description: string;
  interestResultAnalysis?: StepStateAnalysis;
  profitAndLossAnalyis?: StepStateAnalysis;
  valid: boolean;
}
export enum ActionType {
  'Analysis' = 'ANALYSIS',
  'Import' = 'IMPORT',
}
type StepperAction =
  | {
      type: ActionType.Analysis;
      profitAndLossAnalysisActive: boolean;
      interestResultAnalysisActive: boolean;
    }
  | {
      type: ActionType.Import;
      profitAndLossAnalysisActive: boolean;
      interestResultAnalysisActive: boolean;
      timeFrame: TimeFrame;
      importType: ImportType;
      analysisType: AnalysisType;
    };

const initialSteps: StepState[] = [
  {
    label: 'Auswahl der Analyse',
    description: `Wählen Sie aus, für welche Ergebnisse Sie den Vergleich durchführen wollen.
              `,
    valid: false,
  },
  {
    label: 'GBS-Import',
    description: `Laden Sie hier den GBS-Datenexport in die Anwendung. 
                  Eine Erläuterung zur Erstellung des Exports finden Sie über die Schaltfläche [HILFE].`,
    interestResultAnalysis: { timeFrame: TimeFrame.Empty },
    profitAndLossAnalyis: { timeFrame: TimeFrame.Empty },
    valid: false,
  },
  {
    label: 'EVR-Import',
    description: `Laden Sie hier den EVR-Datenexport in die Anwendung. 
                  Eine Erläuterung zur Erstellung des Exports finden Sie über die Schaltfläche [HILFE].`,
    interestResultAnalysis: { timeFrame: TimeFrame.Empty },
    profitAndLossAnalyis: { timeFrame: TimeFrame.Empty },
    valid: false,
  },
  {
    label: 'Auswertungszeitraum und Bagatellgrenze',
    description: `Geben Sie zum Abschluss den zu betrachtenden Auswertungszeitraum und eine Bagatellgrenze für Abweichungen an.
                  Anschließend starten Sie den Vergleich mit einem Klick auf die Schaltfläche [ANALYSIEREN].`,
    valid: false,
  },
];

const stepperReducer: Reducer<StepState[], StepperAction> = (state, action) => {
  switch (action.type) {
    case ActionType.Analysis:
      return state.map((step, index) => {
        if (index === 0) {
          // if one of the checkboxes in the analysis step is checked the underlying validity is set to true
          step.valid = action.profitAndLossAnalysisActive || action.interestResultAnalysisActive;
          return step;
        }
        // if checkbox in analysis step is changed validity of import related steps is checked
        if (step.interestResultAnalysis !== undefined && step.profitAndLossAnalyis !== undefined) {
          step.valid = !(
            (action.profitAndLossAnalysisActive &&
              step.profitAndLossAnalyis.timeFrame === TimeFrame.Empty) ||
            (action.interestResultAnalysisActive &&
              step.interestResultAnalysis.timeFrame === TimeFrame.Empty)
          );
        }
        return step;
      });
    case ActionType.Import:
      return state.map((step) => {
        // set state on import steps regarding the file related timeframe
        // and check their validity
        if (step.interestResultAnalysis !== undefined && step.profitAndLossAnalyis !== undefined) {
          let validityCheck = false;
          if (step.label.includes(action.importType)) {
            validityCheck = true;
            if (action.analysisType === AnalysisType.InterestResult) {
              step.interestResultAnalysis.timeFrame = action.timeFrame;
            } else {
              step.profitAndLossAnalyis.timeFrame = action.timeFrame;
            }
          } else if (
            action.importType === ImportType.Gbs &&
            (step.interestResultAnalysis !== undefined || step.profitAndLossAnalyis !== undefined)
          ) {
            validityCheck = true;
            if (
              action.analysisType === AnalysisType.InterestResult &&
              step.interestResultAnalysis.timeFrame !== action.timeFrame
            ) {
              step.interestResultAnalysis.timeFrame = TimeFrame.Empty;
            } else if (
              action.analysisType === AnalysisType.ProfitAndLoss &&
              step.profitAndLossAnalyis.timeFrame !== action.timeFrame
            ) {
              step.profitAndLossAnalyis.timeFrame = TimeFrame.Empty;
            }
          }
          if (validityCheck) {
            step.valid =
              (action.profitAndLossAnalysisActive &&
                action.interestResultAnalysisActive &&
                step.profitAndLossAnalyis.timeFrame !== TimeFrame.Empty &&
                step.interestResultAnalysis.timeFrame !== TimeFrame.Empty) ||
              (action.profitAndLossAnalysisActive &&
                step.profitAndLossAnalyis.timeFrame !== TimeFrame.Empty &&
                !action.interestResultAnalysisActive) ||
              (action.interestResultAnalysisActive &&
                step.interestResultAnalysis.timeFrame !== TimeFrame.Empty &&
                !action.profitAndLossAnalysisActive);
          }
        }
        return step;
      });
    default:
      return state;
  }
};
export const ImportWizardContext = createContext<ImportWizardContextType>({
  steps: [],
  dispatch: () => {
    return;
  },
  profitAndLossTimeFrame: TimeFrame.Empty,
  interestResultTimeFrame: TimeFrame.Empty,
  interestResultAnalysisActive: false,
  profitAndLossAnalysisActive: false,
  profitAndLossGbsUploaded: false,
  profitAndLossEvrUploaded: false,
  interestResultGbsUploaded: false,
  interestResultEvrUploaded: false,
  deviation: {
    previousAbsolute: null,
    previousRelative: '5',
    absolute: null,
    relative: '5',
    confirmed: false,
  },
  dateRange: {
    selectedStartDate: undefined,
    startDate: undefined,
    selectedEndDate: undefined,
    endDate: undefined,
  },
  setInterestResultAnalysisActive: () => {
    return;
  },
  setProfitAndLossAnalysisActive: () => {
    return;
  },
  setProfitAndLossGbsUploaded: () => {
    return;
  },
  setProfitAndLossEvrUploaded: () => {
    return;
  },
  setInterestResultGbsUploaded: () => {
    return;
  },
  setInterestResultEvrUploaded: () => {
    return;
  },
  setInterestResultTimeFrame: () => {
    return;
  },
  setProfitAndLossTimeFrame: () => {
    return;
  },
  setDeviation: () => {
    return;
  },
  setDateRange: () => {
    return;
  },
});

const ImportWizardContextProvider = ({ children }: React.PropsWithChildren<unknown>) => {
  const [interestResultAnalysisActive, setInterestResultAnalysisActive] = useState(false);
  const [profitAndLossAnalysisActive, setProfitAndLossAnalysisActive] = useState(false);
  const [profitAndLossEvrUploaded, setProfitAndLossEvrUploaded] = useState(false);
  const [profitAndLossGbsUploaded, setProfitAndLossGbsUploaded] = useState(false);
  const [interestResultEvrUploaded, setInterestResultEvrUploaded] = useState(false);
  const [interestResultGbsUploaded, setInterestResultGbsUploaded] = useState(false);
  const [interestResultTimeFrame, setInterestResultTimeFrame] = useState<TimeFrame>(
    TimeFrame.Empty,
  );
  const [profitAndLossTimeFrame, setProfitAndLossTimeFrame] = useState<TimeFrame>(TimeFrame.Empty);
  const [dateRange, setDateRange] = useState<DateRange>({
    startDate:
      localStorage.getItem('startDate') !== null
        ? new Date(localStorage.getItem('startDate') as string)
        : undefined,
    endDate:
      localStorage.getItem('endDate') !== null
        ? new Date(localStorage.getItem('endDate') as string)
        : undefined,
    selectedStartDate:
      localStorage.getItem('selectedStartDate') !== null
        ? new Date(localStorage.getItem('selectedStartDate') as string)
        : undefined,
    selectedEndDate:
      localStorage.getItem('selectedEndDate') !== null
        ? new Date(localStorage.getItem('selectedEndDate') as string)
        : undefined,
  });

  useEffect(() => {
    if (dateRange.startDate !== undefined) {
      localStorage.setItem('startDate', dateRange.startDate.toDateString());
    } else {
      localStorage.removeItem('startDate');
    }
    if (dateRange.endDate !== undefined) {
      localStorage.setItem('endDate', dateRange.endDate.toDateString());
    } else {
      localStorage.removeItem('endDate');
    }
    if (dateRange.selectedStartDate !== undefined) {
      localStorage.setItem('selectedStartDate', dateRange.selectedStartDate.toDateString());
    } else {
      localStorage.removeItem('selectedStartDate');
    }
    if (dateRange.selectedEndDate !== undefined) {
      localStorage.setItem('selectedEndDate', dateRange.selectedEndDate.toDateString());
    } else {
      localStorage.removeItem('selectedEndDate');
    }
  }, [dateRange]);

  const [steps, dispatch] = useReducer(stepperReducer, initialSteps);

  const defaultRelativeDeviation = '5';
  const [deviation, setDeviation] = useState<Deviation>({
    previousAbsolute: localStorage.getItem('absoluteDeviation'),
    previousRelative: localStorage.getItem('relativeDeviation') ?? defaultRelativeDeviation,
    absolute: localStorage.getItem('absoluteDeviation'),
    relative: localStorage.getItem('relativeDeviation') ?? defaultRelativeDeviation,
    confirmed: false,
  });

  useEffect(() => {
    if (!deviation.confirmed) {
      return;
    }
    if (deviation.absolute !== null) {
      localStorage.setItem('absoluteDeviation', deviation.absolute);
    }
    if (deviation.relative === '') {
      setDeviation((prevValidation) => ({
        ...prevValidation,
        relative: defaultRelativeDeviation,
      }));
      return;
    }
    localStorage.setItem('relativeDeviation', deviation.relative);
  }, [deviation, setDeviation]);

  useEffect(() => {
    dispatch({
      type: ActionType.Analysis,
      interestResultAnalysisActive,
      profitAndLossAnalysisActive,
    });
  }, [interestResultAnalysisActive, profitAndLossAnalysisActive]);

  const contextValue = {
    steps,
    dispatch,
    interestResultTimeFrame,
    profitAndLossTimeFrame,
    interestResultAnalysisActive,
    profitAndLossAnalysisActive,
    profitAndLossGbsUploaded,
    profitAndLossEvrUploaded,
    interestResultGbsUploaded,
    interestResultEvrUploaded,
    deviation,
    dateRange,
    setInterestResultAnalysisActive,
    setProfitAndLossAnalysisActive,
    setProfitAndLossEvrUploaded,
    setProfitAndLossGbsUploaded,
    setInterestResultEvrUploaded,
    setInterestResultGbsUploaded,
    setInterestResultTimeFrame,
    setProfitAndLossTimeFrame,
    setDeviation,
    setDateRange,
  };

  return (
    <ImportWizardContext.Provider value={contextValue}>{children}</ImportWizardContext.Provider>
  );
};

export default ImportWizardContextProvider;

export const useWizardContext = () => useContext(ImportWizardContext);
