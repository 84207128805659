import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import App from './App';
import ImportWizardContextProvider from './contexts/importWizardContext/ImportWizardContextProvider';
import './entities/logging/loglevelPlugin';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <ImportWizardContextProvider>
      <App />
    </ImportWizardContextProvider>
  </React.StrictMode>,
);
