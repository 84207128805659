import { DataProvider } from 'react-admin';
import { EvrMappedType } from './MapEvrBalanaceSheet';
import GbsBalanceSheetType from '../importer/types/GbsBalanceSheetType';
import log from 'loglevel';
import { createLogEntry } from '../logging/loggingUtility';

export const mergeBalanceSheet = (dataProvider: DataProvider, evrData: EvrMappedType[]) => {
  return new Promise<{ message: string }>((resolve, reject) => {
    dataProvider
      .getList<GbsBalanceSheetType>('gbsBalanceSheetData', {
        pagination: { page: 1, perPage: 1000000 },
        sort: { field: 'id', order: 'DESC' },
        filter: '',
      })
      .then((gbsResult) => {
        const webworker = new Worker(new URL('./mergeWebworker.ts', import.meta.url));
        webworker.postMessage([evrData, gbsResult.data]);

        webworker.addEventListener('message', (event) => {
          if (event.data[0] === 'complete') {
            resolve({ message: 'ra.notification.analysis_complete' });
            return;
          } else if (event.data[0] === 'error') {
            log.error(event.data[1]);
            reject({ message: (event.data[1] as Error).message });
          } else if (event.data[0] === 'log') {
            createLogEntry(
              event.data[1],
              event.data[2],
              event.data[3],
              event.data[4],
              event.data[5],
              event.data[6],
            );
            return;
          }

          const [resourceName, mergeResult] = event.data;
          dataProvider.clearTableOfResource(resourceName).then(() => {
            dataProvider.createMany(resourceName, { data: mergeResult });
          });
        });
      });
  });
};
