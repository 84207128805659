import { DataProvider, RaRecord } from 'react-admin';
import { Row } from 'read-excel-file';

import { v4 as uuidv4 } from 'uuid';
import log from 'loglevel';
import { unitEuro } from './GbsBalanceSheetImport';
import {
  dateFormatRegex,
  determineDates,
  determineTimeFrame,
  getColumnIndexFromHeaderRow,
  getColumnIndexWithDateFromHeaderRow,
  getValueFromCell,
} from './ImportHelper';
import excelColumnName from 'excel-column-name';

export interface GbsProfitAndLossAccountType extends RaRecord {
  date: Date;
  account: string;
  value: number;
  accountNumber: string;
}

const headerDateRowIndex = 0;
const headerEuroRowIndex = 1;
const beginBodyRowIndex = 2;

export const importGbsProfitAndLossAccountImport = (table: Row[]) => {
  const dateArray: Date[] = [];
  const gbsDataArray: GbsProfitAndLossAccountType[] = [];

  const resultVariableColumnIndex = getColumnIndexFromHeaderRow(
    table[headerEuroRowIndex],
    'Ergebnisgrößen',
  );
  const beginnDataColumnIndex = getColumnIndexWithDateFromHeaderRow(table[headerDateRowIndex]);

  for (let currentRowIndex = 0; currentRowIndex < table.length; currentRowIndex++) {
    const row = table[currentRowIndex];

    let accountNumber = '';
    let accountName = '';

    for (let currentColumnIndex = 0; currentColumnIndex < row.length; currentColumnIndex++) {
      const cell = row[currentColumnIndex];

      // table header
      if (currentRowIndex === headerDateRowIndex && currentColumnIndex >= beginnDataColumnIndex) {
        let date;
        if (cell instanceof Date) {
          date = new Date(cell);
        } else if (dateFormatRegex.test(cell.toString().replace(/\s+/g, ''))) {
          const [day, month, year] = cell.toString().replace(/\s+/g, '').split('.');
          date = new Date(Number(year), Number(month) - 1, Number(day));
        } else {
          const message =
            'Angegebene Zeitscheibe in Spalte ' +
            excelColumnName.intToExcelCol(currentColumnIndex + 1) +
            ' ist nicht im korrekten Datumsformat DD.MM.YYYY: ' +
            cell.toString();
          log.error(message);
          throw new Error(message);
        }

        dateArray.push(date);
      }
      if (currentRowIndex === headerEuroRowIndex && currentColumnIndex >= beginnDataColumnIndex) {
        if (unitEuro !== cell.toString()) {
          const message =
            'Einheit im Header in Spalte ' +
            excelColumnName.intToExcelCol(currentColumnIndex + 1) +
            ' ist nicht korrekt: ' +
            unitEuro +
            ' ist aber ' +
            cell.toString();
          log.error(message);
          throw new Error(message);
        }
      }

      // table body
      if (currentRowIndex >= beginBodyRowIndex) {
        if (cell === null) {
          break;
        }

        if (currentColumnIndex === resultVariableColumnIndex) {
          const [first, ...rest] = cell.toString().split(' ');
          accountNumber = first;
          accountName = rest.join(' ');
        }

        if (currentColumnIndex >= beginnDataColumnIndex) {
          const value = getValueFromCell(cell, currentColumnIndex, currentRowIndex);
          const date = dateArray[currentColumnIndex - 1];

          const gbsData: GbsProfitAndLossAccountType = {
            id: uuidv4(),
            date,
            value,
            account: accountName,
            accountNumber,
          };
          gbsDataArray.push(gbsData);
        }
      }
    }
  }
  return gbsDataArray;
};

const startGbsProfitAndLossAccountImport = (table: Row[], dataProvider: DataProvider) => {
  return dataProvider.clearTableOfResource('gbsProfitAndLostAccountData').then(() => {
    const importedGbsData: GbsProfitAndLossAccountType[] =
      importGbsProfitAndLossAccountImport(table);
    const timeFrame = determineTimeFrame(importedGbsData);
    const { startDate, endDate } = determineDates(importedGbsData);
    dataProvider.createMany('gbsProfitAndLostAccountData', { data: importedGbsData });
    return { message: 'Upload successful.', timeFrame, startDate, endDate };
  });
};

export default startGbsProfitAndLossAccountImport;
