import { DataProvider } from 'react-admin';
import { Row } from 'read-excel-file';
import { v4 as uuidv4 } from 'uuid';
import log from 'loglevel';
import {
  dateFormatRegex,
  getColumnIndexFromHeaderRow,
  getColumnIndexWithDateFromHeaderRow,
  matchPoNrWithOnly3Digits,
  startsWithNumber,
  getValueFromCell,
  determineTimeFrame,
  determineDates,
} from './ImportHelper';
import excelColumnName from 'excel-column-name';
import GbsBalanceSheetType from './types/GbsBalanceSheetType';
import { createLogEntry, OAction, OProcess, OReason } from '../logging/loggingUtility';

const headerDateRowIndex = 0;
const headerKeyFigureRowIndex = 1;
const headerTableValueRowIndex = 2;
const tableBodyRowIndex = 3;
export const unitEuro = '(in EUR)';
export const keyFigures = [
  'Buchwert (Ultimo)',
  'Buchwert (Durchschnitt)',
  'Nominalvolumen (Durchschnitt)',
  'Zinsüberschuss',
  'Liquiditätsbeitrag',
  'Spreadbeitrag',
  'Margenbeitrag nach Liquidität',
  'Strukturbeitrag',
];
const contractPositionPay = 'pay';
const contractPositionReceive = 'receive';
const poNrSwap = '100';

const calculateSwapValue = (payValue: number, receiveValue: number, keyFigure: string) => {
  switch (keyFigure) {
    case 'Buchwert (Ultimo)':
    case 'Buchwert (Durchschnitt)':
      return 0;
    case 'Nominalvolumen (Durchschnitt)':
      return payValue;
    case 'Zinsüberschuss':
      return receiveValue + payValue;
    case 'Liquiditätsbeitrag':
    case 'Spreadbeitrag':
    case 'Margenbeitrag nach Liquidität':
      return receiveValue - payValue;
    case 'Strukturbeitrag':
      return receiveValue + payValue;
    default: {
      const message = `Ergebnisgröße konnte nicht zugeordnet werden: ${keyFigure}`;
      log.error(message);
      throw new Error(message);
    }
  }
};

export const getPayReceiveFromBalanaceSheetSide = (balanceSheetSide: string) => {
  if (balanceSheetSide.toLowerCase().includes('(pay)')) {
    return contractPositionPay;
  } else if (balanceSheetSide.toLowerCase().includes('(receive)')) {
    return contractPositionReceive;
  } else {
    return undefined;
  }
};

export const importGbsData = (table: Row[]) => {
  const poNrKeyFigureDateToGbsDataMap: Map<string, GbsBalanceSheetType> = new Map();
  const columnIndexToDate: Map<number, Date> = new Map();
  const contractPositionToRowMap: Map<string, Row> = new Map();

  const getValueWithContractPosition = (
    contractPosition: string,
    columnIndex: number,
    rowIndex: number,
  ) => {
    const row = contractPositionToRowMap.get(contractPosition);
    if (row === undefined) {
      const message = `Unerwarteter Fehler beim Auslesen der Reihe der Contract Position ${contractPosition}`;
      log.error(message);
      throw new Error(message);
    }
    return getValueFromCell(row[columnIndex], columnIndex, rowIndex);
  };

  // get column indices
  const balanceSheetSideColumnIndex = getColumnIndexFromHeaderRow(
    table[headerTableValueRowIndex],
    'Bilanzseite',
  );

  const beginnDateColumnIndex = getColumnIndexWithDateFromHeaderRow(table[headerDateRowIndex]);
  const balanceSheetPositionColumnIndex = getColumnIndexFromHeaderRow(
    table[headerTableValueRowIndex],
    'BiPo',
  );

  for (let rowIndex = 0; rowIndex < table.length; rowIndex++) {
    const row: Row = table[rowIndex];
    let positionNumber = '';
    let date = new Date();
    let balanceSheetPositionName = '';
    let allValuesAreZeroInRow = true;
    const poNrKeyFigureDateToGbsDataInRowMap: Map<string, GbsBalanceSheetType> = new Map();

    for (let columnIndex = 0; columnIndex < row.length; columnIndex++) {
      const cell = row[columnIndex];

      // table header with date
      if (rowIndex === headerDateRowIndex && columnIndex >= beginnDateColumnIndex) {
        if (cell instanceof Date) {
          date = new Date(cell);
          columnIndexToDate.set(columnIndex, date);
          // empty cells from composite excel cells get the same date
        } else if (!cell) {
          columnIndexToDate.set(columnIndex, date);
        } else if (dateFormatRegex.test(cell.toString().replace(/\s+/g, ''))) {
          const [day, month, year] = cell.toString().replace(/\s+/g, '').split('.');
          date = new Date(Number(year), Number(month) - 1, Number(day));
          columnIndexToDate.set(columnIndex, date);
        } else {
          const message = `Angegebene Zeitscheibe ist nicht im korrekten Datumsformat DD.MM.YYYY: ${cell.toString()} in Spalte ${excelColumnName.intToExcelCol(
            columnIndex + 1,
          )}`;
          log.error(message);
          throw new Error(message);
        }
      }

      // table header with key figures
      if (rowIndex === headerKeyFigureRowIndex && columnIndex >= beginnDateColumnIndex) {
        if (!keyFigures.includes(cell?.toString())) {
          const message = `Falsche Kennzahl im Header in Spalte ${excelColumnName.intToExcelCol(
            columnIndex + 1,
          )} enthalten: ${cell.toString()}`;
          log.error(message);
          throw new Error(message);
        }
      }

      // table header with unit euro
      if (rowIndex === headerTableValueRowIndex && columnIndex >= beginnDateColumnIndex) {
        if (unitEuro.normalize() !== cell?.toString().normalize()) {
          const message = `Einheit im Header in Spalte ${excelColumnName.intToExcelCol(
            columnIndex + 1,
          )} ist nicht korrekt: ${unitEuro} ist aber ${cell.toString()}`;
          log.error(message);
          throw new Error(message);
        }
      }

      // table body && column with BiPo has content
      if (rowIndex >= tableBodyRowIndex && row[balanceSheetPositionColumnIndex]) {
        // column with position number
        if (columnIndex === balanceSheetPositionColumnIndex) {
          const cellContent = cell.toString().trim();

          // rows are only considered if they have position number in 'BiPo' column
          if (!startsWithNumber(cellContent)) {
            break;
          }

          const [first, second] = cellContent.split('-', 2);

          if (!matchPoNrWithOnly3Digits(first.trim())) {
            const message = `Die Positionsnummer in Spalte BiPo ${excelColumnName.intToExcelCol(
              columnIndex + 1,
            )} Zeile ${
              rowIndex + 1
            } muss aus 3 Zahlen bestehen. Bitte prüfen Sie die Einstellung im Export.`;
            log.error(message);
            throw new Error(message);
          }

          positionNumber = first.trim();
          balanceSheetPositionName = second.trim();

          // ignore position number 000 and 200 and use only position number 300 with contract position pay
          if (
            positionNumber === '000' ||
            positionNumber === '200' ||
            (positionNumber === '300' &&
              getPayReceiveFromBalanaceSheetSide(row[balanceSheetSideColumnIndex]?.toString()) ===
                contractPositionPay)
          ) {
            createLogEntry(
              positionNumber,
              balanceSheetPositionName,
              OProcess.ImportGBSBalance,
              OAction.Ignored,
              OReason.IgnoredValues,
              columnIndex,
              rowIndex,
            );
            break;
          }

          if (positionNumber === poNrSwap) {
            const contractPosition = getPayReceiveFromBalanaceSheetSide(
              row[balanceSheetSideColumnIndex]?.toString(),
            );
            if (contractPosition === undefined) {
              const message = `Die Positionsnummer 100 in Zeile ${
                rowIndex + 1
              } hat keine Contractposition in Spalte ${balanceSheetSideColumnIndex}`;
              throw message;
            }
            contractPositionToRowMap.set(contractPosition, row);
            // calculation and data storage only with 2 contract positions
            if (contractPositionToRowMap.size !== 2) {
              createLogEntry(
                positionNumber,
                balanceSheetPositionName,
                OProcess.ImportGBSBalance,
                OAction.Discarded,
                OReason.InvalidContractPositions,
              );
              break;
            }
          }
        }

        // columns with values
        if (columnIndex >= beginnDateColumnIndex) {
          const currentKeyFigure = table[headerKeyFigureRowIndex][columnIndex]?.toString();
          const currentTimeSerie = columnIndexToDate.get(columnIndex);

          if (currentTimeSerie) {
            let gbsValue: number;

            if (positionNumber === poNrSwap) {
              // don't remove position number 100
              allValuesAreZeroInRow = false;

              const gbsValuePay = getValueWithContractPosition(
                contractPositionPay,
                columnIndex,
                rowIndex,
              );
              const gbsValueReceive = getValueWithContractPosition(
                contractPositionReceive,
                columnIndex,
                rowIndex,
              );

              gbsValue = calculateSwapValue(gbsValuePay, gbsValueReceive, currentKeyFigure);
            } else {
              // default case (poNr != 100)
              gbsValue = getValueFromCell(cell, columnIndex, rowIndex);
              if (gbsValue !== 0) {
                allValuesAreZeroInRow = false;
              }
            }

            const poNrDateKeyFigureKey = [positionNumber, currentTimeSerie, currentKeyFigure].join(
              ',',
            );

            const existingGbsElement = poNrKeyFigureDateToGbsDataMap.get(poNrDateKeyFigureKey);
            let data: GbsBalanceSheetType;

            // if gbs element existis with equal position number then aggregate
            if (existingGbsElement) {
              data = {
                id: uuidv4(),
                date: currentTimeSerie,
                positionNumber: positionNumber,
                balanceSheetPositionName: balanceSheetPositionName,
                keyFigureName: currentKeyFigure,
                value: existingGbsElement.value + gbsValue,
              };
            } else {
              data = {
                id: uuidv4(),
                date: currentTimeSerie,
                positionNumber: positionNumber,
                balanceSheetPositionName: balanceSheetPositionName,
                keyFigureName: currentKeyFigure,
                value: gbsValue,
              };
            }

            poNrKeyFigureDateToGbsDataInRowMap.set(poNrDateKeyFigureKey, data);
          } else {
            createLogEntry(
              positionNumber,
              currentKeyFigure,
              OProcess.ImportGBSBalance,
              OAction.Error,
              OReason.InvalidDateFormat,
            );

            // TODO lyp add error after refcatoring
            log.error(
              `Zeitreihe aus Header nicht definiert aus Spaltenummer: + ${excelColumnName.intToExcelCol(
                columnIndex + 1,
              )}`,
            );
          }
        }
      }
    }

    // ignore row with only 0
    if (!allValuesAreZeroInRow) {
      poNrKeyFigureDateToGbsDataInRowMap.forEach((value, key) =>
        poNrKeyFigureDateToGbsDataMap.set(key, value),
      );
    }
  }

  return Array.from(poNrKeyFigureDateToGbsDataMap.values());
};

const startGbsBalanceSheetImporter = (rows: Row[], dataProvider: DataProvider) => {
  return dataProvider.clearTableOfResource('gbsBalanceSheetData').then(() => {
    const importedGbsData: GbsBalanceSheetType[] = importGbsData(rows);
    const timeFrame = determineTimeFrame(importedGbsData);
    const { startDate, endDate } = determineDates(importedGbsData);
    dataProvider.createMany('gbsBalanceSheetData', { data: importedGbsData });
    return { message: 'File Upload successful', timeFrame, startDate, endDate };
  });
};

export default startGbsBalanceSheetImporter;
