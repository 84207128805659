import { CircularProgress, Box, Stack, Typography, Divider, Chip } from '@mui/material';
import log from 'loglevel';
import { useDataProvider, useNotify } from 'react-admin';
import Dropzone, { FileRejection } from 'react-dropzone';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { useState } from 'react';

const SciImportJSONPage = () => {
  const dataProvider = useDataProvider();
  const [loading, setLoading] = useState(false);
  const notify = useNotify();
  const [progress, setProgress] = useState(0);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const onProgressHandler = (progress: any) => {
    if (progress.completedRows === 0) {
      return;
    }
    setProgress((progress.completedRows / progress.totalRows) * 100);
  };
  const onDropHandler = async (acceptedFiles: File[]) => {
    setLoading(true);
    try {
      await dataProvider.importDatabaseDump(acceptedFiles[0], onProgressHandler);
    } catch (error: unknown) {
      let message = '';
      if (error instanceof Error) {
        message = `Fehler beim Import: ${error.message}`;
      } else {
        message = 'Unbekannter Fehler';
      }
      notify(message, { type: 'error' });
    }
    setLoading(false);
    setProgress(0);
  };

  const onDropRejectHandler = (fileRejections: FileRejection[]) => {
    log.log('Fehler beim Uploadhandling' + fileRejections[0]);
    notify('Falsches Dateiformat. Derzeit wird nur ".json" unterstützt.', { type: 'error' });
  };

  return (
    <>
      <Divider>
        <Chip label=' Import aller Daten' />
      </Divider>
      <br />
      <Box sx={{ width: 600, backgroundColor: 'lightgrey', borderRadius: '5px' }}>
        <Dropzone
          onDropAccepted={onDropHandler}
          onDropRejected={onDropRejectHandler}
          maxFiles={1}
          accept={'application/json'}>
          {({ getRootProps, getInputProps }) => (
            <Box
              mx={{ padding: 5, display: 'flex', alignItems: 'center', height: 80 }}
              {...getRootProps()}>
              <input {...getInputProps()} />
              <Stack direction='row' alignItems='center' justifyContent='center' gap={1}>
                <UploadFileIcon sx={{ marginRight: '10px' }} />
                {loading ? (
                  <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                    <CircularProgress variant='determinate' value={progress} />
                    <Box
                      sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}>
                      <Typography
                        variant='subtitle2'
                        component='div'
                        color='text.secondary'>{`${Math.round(progress)}%`}</Typography>
                    </Box>
                  </Box>
                ) : (
                  <Typography variant='body1'>
                    Zum Importieren des Datenbankexports klicken Sie bitte hier oder ziehen Sie die
                    Exportdatei hinein.
                  </Typography>
                )}
              </Stack>
            </Box>
          )}
        </Dropzone>
      </Box>
    </>
  );
};

export default SciImportJSONPage;
