import { AppBarClasses, SidebarToggleButton } from 'react-admin';
import { ReactComponent as PPILogo } from './PPI_white.svg';

import { AppBar, Stack, Theme, Toolbar, Typography, useMediaQuery } from '@mui/material';

const CustomSciAppBar = () => {
  const isXSmall = useMediaQuery<Theme>((theme) => theme.breakpoints.down('sm'));

  return (
    <AppBar>
      <Toolbar
        sx={{ display: 'flex', justifyContent: 'space-between' }}
        disableGutters
        variant={isXSmall ? 'regular' : 'dense'}
        className={AppBarClasses.toolbar}>
        <SidebarToggleButton className={AppBarClasses.menuButton} />
        <Typography variant='h5'>Rollout Neue Banksteuerung: GBS - EVR Abgleich</Typography>
        <Stack direction='row' mr={2} spacing={2} alignItems='center' justifyContent='center'>
          <a
            href='https://www.ppi.de/banken/banksteuerung-und-risikomanagement/beratung-fuer-sparkassen/'
            target='_blank'
            rel='noreferrer'>
            <PPILogo style={{ verticalAlign: 'middle' }} width='30' height='30' />
          </a>
          <Typography variant='body2' align='center'>
            {`V. ${process.env.REACT_APP_VERSION}`}
          </Typography>
        </Stack>
      </Toolbar>
    </AppBar>
  );
};

export default CustomSciAppBar;
