import log from 'loglevel';
import { createErrorLogEntry } from './loggingUtility';

const getStacktrace = () => {
  try {
    throw new Error();
  } catch (error) {
    const stacktrace = (error as Error).stack ?? '';
    const lines = stacktrace.split('\n');
    lines.splice(0, 3);
    return lines.join('\n');
  }
};

const getConstructorName = (obj: Record<string, unknown>) => {
  if (!Object.getOwnPropertyDescriptor || !Object.getPrototypeOf) {
    return Object.prototype.toString.call(obj).slice(8, -1);
  }

  while (obj) {
    const descriptor = Object.getOwnPropertyDescriptor(obj, 'constructor');
    if (
      descriptor !== undefined &&
      typeof descriptor.value === 'function' &&
      descriptor.value.name !== ''
    ) {
      return descriptor.value.name;
    }

    obj = Object.getPrototypeOf(obj);
  }

  return '';
};

let CIRCULAR_ERROR_MESSAGE: string;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const tryStringify = (arg: any) => {
  try {
    return JSON.stringify(arg);
  } catch (error) {
    // Populate the circular error message lazily
    if (!CIRCULAR_ERROR_MESSAGE) {
      try {
        const a = { a: {} };
        a.a = a;
        JSON.stringify(a);
      } catch (circular) {
        CIRCULAR_ERROR_MESSAGE = (circular as Error).message;
      }
    }
    if ((error as Error).message === CIRCULAR_ERROR_MESSAGE) {
      return '[Circular]';
    }
    throw error;
  }
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const interpolate = (array: any[]) => {
  // enables interpolation for log.error statements:
  // log.error('Number interpolation: %d %d %d %d', 16, 1e6, '16', '1e6');
  // -> Error: Number interpolation: 16 1000000 16 1000000
  let result = '';
  let index = 0;

  if (array.length > 1 && typeof array[0] === 'string') {
    result = array[0].replace(/(%?)(%([sdjo]))/g, (match, escaped, ptn, flag) => {
      if (!escaped) {
        index += 1;
        const arg = array[index];
        let a = '';
        switch (flag) {
          case 's':
            a += arg;
            break;
          case 'd':
            a += +arg;
            break;
          case 'j':
            a = tryStringify(arg);
            break;
          case 'o': {
            let obj = tryStringify(arg);
            if (obj[0] !== '{' && obj[0] !== '[') {
              obj = `<${obj}>`;
            }
            a = getConstructorName(arg) + obj;
            break;
          }
        }
        return a;
      }
      return match;
    });

    // update escaped %% values
    result = result.replace(/%{2,2}/g, '%');

    index += 1;
  }

  // arguments remaining after formatting
  if (array.length > index) {
    if (result) {
      result += ' ';
    }
    result += array.slice(index).join(' ');
  }

  return result;
};

const originalFactory = log.methodFactory;
log.methodFactory = (methodName, logLevel, loggerName) => {
  const rawMethod = originalFactory(methodName, logLevel, loggerName);
  return (...args) => {
    if (methodName === 'error') {
      const stacktrace = getStacktrace();
      const log = `${methodName.toUpperCase()}${
        loggerName ? ` (${loggerName.toString()})` : ''
      }: ${interpolate(args)}${stacktrace ? `\n${stacktrace}` : ''}`;

      createErrorLogEntry(log);
    }
    rawMethod(...args);
  };
};
// TODO: Make loglevel not static and dependend from .env
log.setLevel(log.levels.ERROR);
