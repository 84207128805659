import { Row } from 'read-excel-file';
import log from 'loglevel';
import { Cell } from 'read-excel-file/types';
import excelColumnName from 'excel-column-name';
import { TimeFrame } from './types/TimeFrameType';

/**
 * Regex with date format DD.MM.YYYY
 */
export const dateFormatRegex = new RegExp(/[0-9][0-9].[0-9][0-9].[0-9][0-9][0-9][0-9]/);

/**
 * Regex with date format MM.YYYY
 */
export const monthYearDateFormatRegex = new RegExp(/[0-9][0-9].[0-9][0-9][0-9][0-9]/);

/**
 * Regex with date format YYYY
 */
export const yearDateFormatRegex = new RegExp(/[0-9][0-9][0-9][0-9]/);

const startsWith3DigitsRegex = new RegExp(/^[0-9][0-9][0-9]/);

/**
 * Checks if the string is numeric
 * @param str the string to check against
 * @returns boolean
 */
export const isNumeric = (str: string): boolean => {
  if (typeof str !== 'string') {
    return false;
  }

  if (str.trim() === '') {
    return false;
  }

  return !Number.isNaN(Number(str));
};

const checkColumnIndex = (columnIndex: number, messagePart: string) => {
  if (columnIndex === -1) {
    const message =
      "Zelle mit '" +
      messagePart +
      "' ist nicht im Tabellenkopf enthalten. Bitte prüfen Sie die Datei!";
    log.error(message);
    throw new Error(message);
  }
};

/**
 * Get column index from header row.
 * @param row header row
 * @param matcher matching string or regex
 * @returns column index or throw error
 */
export const getColumnIndexFromHeaderRow = (row: Row, matcher: string | RegExp) => {
  let errorMessagePart;
  let clmIndex;
  if (typeof matcher === 'string') {
    clmIndex = row.findIndex(
      (cell) => matcher.replace(/\s+/g, '') === cell?.toString().replace(/\s+/g, ''),
    );
    errorMessagePart = matcher;
  } else {
    clmIndex = row.findIndex((cell) => matcher.test(cell?.toString().replace(/\s+/g, '')));
    errorMessagePart = 'Datumsangabe';
  }

  checkColumnIndex(clmIndex, errorMessagePart);
  return clmIndex;
};

/**
 * Get column index with date (DD.MM.YYYY) from header row.
 * @param row header row
 * @returns column index or throw error
 */
export const getColumnIndexWithDateFromHeaderRow = (row: Row) => {
  const clmIndex = row.findIndex(
    (cell) => dateFormatRegex.test(cell?.toString().replace(/\s+/g, '')) || cell instanceof Date,
  );

  checkColumnIndex(clmIndex, 'Datum im Format TT.MM.JJJJ');
  return clmIndex;
};

/**
 * Extract date with month and year from Excel cell, e.g. 11.2022 -> 01.11.2022
 * @param cell Cell
 * @param columnIndex number with index
 * @returns Date
 */
export const getMonthYearDateFromCell = (cell: Cell, columnIndex: number) => {
  if (!monthYearDateFormatRegex.test(cell.toString())) {
    const message =
      'Fehlerhafte Zeitangabe ' +
      cell.toString() +
      ' in Spalte ' +
      excelColumnName.intToExcelCol(columnIndex + 1);
    log.error(message);
    throw new Error(message);
  }

  const [month, year] = cell.toString().replace(/\s+/g, '').split('.');
  return getLastDayDateofYearMonth(Number(year), Number(month));
};

/**
 * Returns date of the last day of given year and month combination.
 * @param year year
 * @param month month
 * @returns Date
 */
export const getLastDayDateofYearMonth = (year: number, month: number) => {
  return new Date(year, month, 0);
};

/**
 * Get value from cell and return a number.
 * @param cell Cell
 * @param columnIndex number with index
 * @param rowIndex number with index
 * @returns number
 */
export const getValueFromCell = (cell: Cell, columnIndex: number, rowIndex: number) => {
  const value = Number(cell);
  if (isNaN(value)) {
    const message = `Zelle in Spalte ${excelColumnName.intToExcelCol(columnIndex + 1)} und Zeile ${
      rowIndex + 1
    } beinhaltet keine Zahl`;
    log.error(message);
    throw message;
  }
  return value;
};

/**
 * Get cells from row and throws error if cell is null.
 * @param row Row[]
 * @param columnIndexes number[] with indexes
 * @param rowIndex number with index
 * @returns Cell[]
 */
export const getCellsFromRow = (row: Row, columnIndexes: number[], rowIndex: number): Cell[] => {
  const emptyCells = [];
  const cells = [];
  for (const columnIndex of columnIndexes) {
    const cell = row[columnIndex];
    if (cell === null) {
      emptyCells.push(excelColumnName.intToExcelCol(columnIndex + 1));
      continue;
    }
    cells.push(cell);
  }
  if (emptyCells.length === 0) {
    return cells;
  }
  const multipleEmptyCells = emptyCells.length > 1;
  const message = `Die ${
    multipleEmptyCells ? 'Zellen in den Spalten' : 'Zelle in Spalte'
  } ${emptyCells.join(', ')} und Zeile ${rowIndex + 1} ${
    multipleEmptyCells ? 'müssen' : 'muss'
  } einen Wert enthalten!`;
  throw new Error(message);
};

/**
 * Check, if string is a position number with only 3 digits.
 * @param poNr position number
 * @returns Return true if position number has 3 digits
 */
export const matchPoNrWithOnly3Digits = (poNr: string) => {
  return startsWith3Digits(poNr) && poNr.length === 3;
};

export const startsWithNumber = (string: string) => {
  return /^[0-9]/.test(string);
};

/**
 * Check, if string starts with 3 digits.
 * @param string string
 * @returns Return true if string starts with 3 digits.
 */
export const startsWith3Digits = (string: string) => {
  return startsWith3DigitsRegex.test(string);
};

/**
 * Get the timeframe for the imported data.
 * @param importData the imported data
 * @returns TimeFrame.
 */
export const determineTimeFrame = (importData: { date: Date }[]): TimeFrame => {
  let prevDate = {};
  if (importData.length === 1) {
    return TimeFrame.Yearly;
  }
  for (const { date: currentDate } of importData) {
    if (
      prevDate instanceof Date &&
      currentDate instanceof Date &&
      prevDate.toDateString() !== currentDate.toDateString()
    ) {
      const timeDifference = Math.abs(prevDate.getFullYear() - currentDate.getFullYear());
      if (timeDifference > 0) {
        return TimeFrame.Yearly;
      }
      return TimeFrame.Monthly;
    }
    prevDate = currentDate;
  }
  return TimeFrame.Empty;
};

export const determineDates = (
  importData: { date: Date }[],
): { startDate: Date; endDate: Date } => {
  return { startDate: importData[0].date, endDate: importData[importData.length - 1].date };
};
