import { Layout, LayoutProps } from '@react-admin/ra-enterprise';
import React from 'react';
import SciAppBar from './SciAppBar';
import SciMenu from './SciMenu';

const SciLayout = (props: LayoutProps) => {
  return <Layout {...props} menu={SciMenu} appBar={SciAppBar} />;
};

export default SciLayout;
