import {
  Box,
  Card,
  CardContent,
  Stack,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import {
  Datagrid,
  DatagridBody,
  DatagridBodyProps,
  DatagridProps,
  DatagridRowProps,
  FilterForm,
  Pagination,
  downloadCSV,
  ExportButton,
  ListBase,
  RecordContextProvider,
  TextField,
  TextInput,
  SelectInput,
} from 'react-admin';
import { generateChoicesArray, LoggingEntry } from '../../entities/logging/loggingUtility';
interface CustomDatagridRowProps extends DatagridRowProps {
  record?: LoggingEntry;
}

const listFilters = [
  <TextInput
    key='positionNumberSearch'
    label='Positionsnummer'
    source='positionNumber'
    variant='outlined'
    type='number'
    alwaysOn
  />,
  <TextInput
    key='keyFigureSearch'
    label='Kennzahl'
    source='keyFigure'
    variant='outlined'
    alwaysOn
  />,
  <SelectInput
    key='processSelect'
    label='Prozess'
    source='process'
    choices={generateChoicesArray('process')}
    variant='outlined'
    alwaysOn
  />,
  <SelectInput
    key='actionSelect'
    label='Behandlung'
    source='action'
    choices={generateChoicesArray('action')}
    variant='outlined'
    alwaysOn
  />,
  <SelectInput
    key='reasonSelect'
    label='Grund'
    source='reason'
    choices={generateChoicesArray('reason')}
    variant='outlined'
    alwaysOn
  />,
];

const ListToolbar = () => (
  <Stack direction='row' sx={{ top: 0, position: 'sticky', background: 'white' }}>
    <FilterForm filters={listFilters} style={{ flexWrap: 'nowrap' }} />
    <ExportButton maxResults={10000} />
  </Stack>
);

const CustomDatagridRow = ({ record }: CustomDatagridRowProps) => (
  <RecordContextProvider value={record}>
    <TableRow>
      <TableCell>
        <TextField source='positionNumber' label='Positionsnummer' />
      </TableCell>
      <TableCell>
        <TextField source='keyFigure' label='Kennzahl' />
      </TableCell>
      <TableCell>
        <TextField source='process' label='Prozess' />
      </TableCell>
      <TableCell>
        <TextField source='action' label='Behandlung' />
      </TableCell>
      <TableCell>
        <TextField source='reason' label='Grund' />
      </TableCell>
    </TableRow>
  </RecordContextProvider>
);

const CustomDatagridBody = (props: DatagridBodyProps) => (
  <DatagridBody {...props} row={<CustomDatagridRow />} />
);

const CustomDatagridHeader = () => (
  <TableHead>
    <TableRow>
      <TableCell>Positionsnummer</TableCell>
      <TableCell>Kennzahl</TableCell>
      <TableCell>Prozess</TableCell>
      <TableCell>Behandlung</TableCell>
      <TableCell>Grund</TableCell>
    </TableRow>
  </TableHead>
);

const CustomDatagrid = (props: DatagridProps) => {
  return (
    <Datagrid
      {...props}
      header={CustomDatagridHeader}
      body={CustomDatagridBody}
      bulkActionButtons={false}
      optimized>
      <></>
    </Datagrid>
  );
};

const csvExporter = (items: Array<LoggingEntry>) => {
  let csv = 'Positionsnummer,Kennzahl,Prozess,Behandlung,Grund\n';
  items.forEach(({ positionNumber, keyFigure, process, action, reason }: LoggingEntry) => {
    csv += positionNumber + ',' + keyFigure + ',' + process + ',' + action + ',' + reason + '\n';
  });
  downloadCSV(csv, 'Log');
};

const SciLoggingList = ({ resourceName }: { resourceName: string }) => {
  return (
    <Box mt={2} sx={{ width: '90vw' }}>
      <Card sx={{ mb: 2 }}>
        <CardContent>
          <Typography variant='h4'>Verarbeitungsdetails</Typography>
          <ListBase
            sort={{ field: 'positionNumber', order: 'AlphaNumerical' }}
            perPage={25}
            disableSyncWithLocation
            resource={resourceName}
            exporter={csvExporter}>
            <Box sx={{ display: 'block', overflow: 'auto', maxHeight: '82vh' }}>
              <ListToolbar />
              <CustomDatagrid
                sx={{
                  '.MuiStack-root': {
                    top: 0,
                    position: 'sticky',
                    background: 'white',
                  },
                  '.MuiTableHead-root': {
                    top: '56px',
                    position: 'sticky',
                    background: 'white',
                  },
                }}
              />
            </Box>
            <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />
          </ListBase>
        </CardContent>
      </Card>
    </Box>
  );
};

export default SciLoggingList;
