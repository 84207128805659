import polyglotI18nProvider from 'ra-i18n-polyglot';
import { mergeTranslations } from 'react-admin';

import fr from './fr';
import en from './en';
import de from './de';
import { raAuditLogLanguageEnglish, raAuditLogLanguageFrench } from '@react-admin/ra-audit-log';

const I18nProvider = polyglotI18nProvider((locale) => {
  // TODO: Make the import conditional
  /* switch (locale) {
        case 'fr': return import('./fr').then(messages => messages.default);
        case 'en': return import('./en').then(messages => messages.default);
        default: return import('./de').then(messages => messages.default);
    }*/
  switch (locale) {
    case 'fr':
      return mergeTranslations(fr, raAuditLogLanguageFrench);
    case 'en':
      return mergeTranslations(en, raAuditLogLanguageEnglish);
    default:
      return de;
  }
}, 'de');

export default I18nProvider;
