import { Box, IconButton, Stack, TableCell, TableHead, TableRow } from '@mui/material';
import { Dispatch, SetStateAction, useCallback, useContext, useState } from 'react';
import {
  Datagrid,
  DatagridBody,
  DatagridBodyProps,
  DatagridProps,
  DatagridRowProps,
  FilterForm,
  Pagination,
  downloadCSV,
  FunctionField,
  ListBase,
  NumberInput,
  RecordContextProvider,
  TextInput,
  useListContext,
  ExportButton,
  TextField as TextFieldRA,
} from 'react-admin';
import ComparisonBaseDataType from '../../entities/comparison/ComparisonBaseDataType';
import SciComparisonTabExpandPanel from './SciComparisonTabExpandPanel';
import { GUVContext } from './SciComparisonView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import DataPointType from '../../entities/comparison/DataPointType';
import {
  Deviation,
  useWizardContext,
} from '../../contexts/importWizardContext/ImportWizardContextProvider';
import GbsDataCmpType from '../../entities/comparison/GbsDataCmpType';
import ComparsionProfitAndLossAccountDataType from '../../entities/comparison/ComparsionProfitAndLossAccountDataType';
import { calculateAbsoluteDeltaInPercent } from '../../entities/merge/mergeUtility';
import CustomDatePicker, { CustomDatePickerType } from '../../fields/CustomDatePicker';

const listFilters = (
  guvContext: boolean,
  startDate: Date | undefined,
  endDate: Date | undefined,
  setStartDate: Dispatch<SetStateAction<Date | undefined>>,
  setEndDate: Dispatch<SetStateAction<Date | undefined>>,
) => [
  <TextInput
    key='positionNumberSearch'
    label='Positionsnummer'
    source='positionNumber'
    variant='outlined'
    alwaysOn
    type='number'
  />,
  <TextInput
    key='balanceSheetPositionSearch'
    label={guvContext ? 'GuV-Position' : 'Bilanzposition'}
    source={guvContext ? 'name' : 'balanceSheetPosition'}
    variant='outlined'
    alwaysOn
  />,
  <CustomDatePicker
    key='startDateFilter'
    label='Startdatum'
    source='startDate'
    dateType={CustomDatePickerType.START_DATE}
    dispatch={setStartDate}
    borderDate={endDate}
    alwaysOn
  />,
  <CustomDatePicker
    key='endDateFilter'
    label='Enddatum'
    source='endDate'
    dateType={CustomDatePickerType.END_DATE}
    dispatch={setEndDate}
    borderDate={startDate}
    alwaysOn
  />,
  <NumberInput
    key='deviationFilter'
    label='Minimale Abweichung (%)'
    source='deviation'
    variant='outlined'
    min={0}
    max={100}
    alwaysOn
  />,
];

const ListToolbar = (
  startDate: Date | undefined,
  endDate: Date | undefined,
  setStartDate: Dispatch<SetStateAction<Date | undefined>>,
  setEndDate: Dispatch<SetStateAction<Date | undefined>>,
) => {
  const guvContext = useContext(GUVContext);
  return (
    <Stack direction='row'>
      <FilterForm
        filters={listFilters(guvContext, startDate, endDate, setStartDate, setEndDate)}
        style={{ flexWrap: 'nowrap' }}
      />
      <ExportButton />
    </Stack>
  );
};

const evrGbsDeviationColor = (configuredDeviation: Deviation, dataPoint: DataPointType) => {
  const red = '#ff0c0c';
  const green = '#51bd01';
  const evrValue = dataPoint.evrData.value;
  const gbsValue = dataPoint.gbsData.reduce(
    (accumulator, currentGbsData) => accumulator + currentGbsData.value,
    0,
  );
  const relativeDeviation = calculateAbsoluteDeltaInPercent(evrValue, gbsValue) * 100;
  const absoluteDeviation = Math.abs(gbsValue - evrValue);
  let configuredAbsoluteDeviation, configuredRelativeDeviation;
  if (configuredDeviation.confirmed) {
    configuredAbsoluteDeviation = configuredDeviation.absolute;
    configuredRelativeDeviation = configuredDeviation.relative;
  } else {
    configuredAbsoluteDeviation = configuredDeviation.previousAbsolute;
    configuredRelativeDeviation = configuredDeviation.previousRelative;
  }
  if (
    (configuredAbsoluteDeviation !== null && +configuredAbsoluteDeviation > absoluteDeviation) ||
    +configuredRelativeDeviation > relativeDeviation
  ) {
    return green;
  }
  return red;
};

interface CustomDatagridRowProps extends DatagridRowProps {
  record?: ComparisonBaseDataType;
}
const CustomDatagridRow = ({ record }: CustomDatagridRowProps) => {
  const [expanded, setExpanded] = useState(false);
  const toggle = useCallback(() => setExpanded((prevState) => !prevState), []);
  const guvContext = useContext(GUVContext);
  const { deviation } = useWizardContext();

  return (
    <RecordContextProvider value={record}>
      <TableRow className={expanded ? 'expanded' : 'collapsed'}>
        <TableCell>
          <IconButton onClick={toggle}>
            {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </TableCell>
        <TableCell>
          <TextFieldRA source='positionNumber' label='Positionsnummer' />
        </TableCell>
        <TableCell>
          {guvContext ? (
            <TextFieldRA source='name' label='GuV-Position' />
          ) : (
            <TextFieldRA source='balanceSheetPosition' label='Bilanzposition' />
          )}
        </TableCell>
        {record?.values.sort(sortDataPoints).map((dataPoint) => (
          <TableCell key={`${dataPoint.evrData.id}-${dataPoint.value}`} align='right'>
            <FunctionField
              key={dataPoint.value}
              label={dataPoint.date.toLocaleDateString('de-DE', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              })}
              render={() => `${formatNumber(dataPoint.value * 100)}%`}
              sx={{ color: evrGbsDeviationColor(deviation, dataPoint) }}
            />
          </TableCell>
        ))}
      </TableRow>
      {expanded ? <SciComparisonTabExpandPanel /> : <></>}
    </RecordContextProvider>
  );
};

const CustomDatagridBody = (props: DatagridBodyProps) => (
  <DatagridBody {...props} row={<CustomDatagridRow />} />
);

const CustomDatagridHeader = () => {
  const { data, isLoading } = useListContext<ComparisonBaseDataType>();
  const guvContext = useContext(GUVContext);
  if (isLoading) {
    return null;
  }
  return (
    <TableHead>
      <TableRow>
        <TableCell />
        <TableCell>Positionsnummer</TableCell>
        <TableCell>{guvContext ? 'GuV-Position' : 'Bilanzposition'}</TableCell>
        {data
          .at(0)
          ?.values.sort(sortDataPoints)
          .map((dataPoint, index) => (
            <TableCell key={index} align='right'>
              {dataPoint.date.toLocaleDateString('de-DE', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
              })}
            </TableCell>
          ))}
      </TableRow>
    </TableHead>
  );
};

const CustomDatagrid = (props: DatagridProps) => (
  <Datagrid
    {...props}
    rowStyle={datagridRowStyle}
    header={CustomDatagridHeader}
    body={CustomDatagridBody}
    bulkActionButtons={false}
    optimized
    sx={stickyTableSx}>
    <></>
  </Datagrid>
);

const csvStringBuilder = (
  guvContext: boolean,
  items: Array<ComparisonBaseDataType | ComparsionProfitAndLossAccountDataType>,
) => {
  let csv = '';

  // Headers
  let mainHeader = ',Positionsnummer,' + (guvContext ? 'GuV-Position' : 'Bilanzposition');
  const dates = items[0].values.sort((v1: DataPointType, v2: DataPointType) => {
    return v1.date.getTime() - v2.date.getTime();
  });
  dates.forEach((date: DataPointType) => {
    const dateString = date.date.toLocaleDateString('de-DE', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    mainHeader += ',' + dateString;
  });
  mainHeader += '\n';

  // Items
  let mainString = '';
  let EVRString = '';
  let GBSStrings: string[] = [];
  let GBSSumString = '';
  let deviationString = '';
  let commentString;

  items.forEach((item: ComparisonBaseDataType | ComparsionProfitAndLossAccountDataType) => {
    mainString =
      ',' + item.positionNumber + ',"' + (guvContext ? item.name : item.balanceSheetPosition) + '"';

    EVRString = 'EVR';
    GBSStrings = [];
    GBSSumString = 'Summe GBS,,';
    deviationString = 'Abweichung,,';
    commentString = item.comment;

    item.values
      .sort((v1: DataPointType, v2: DataPointType) => {
        return v1.date.getTime() - v2.date.getTime();
      })
      .forEach((value: DataPointType, dIndex: number) => {
        if (dIndex === 0) {
          EVRString += ',' + value.evrData.positionNumber + ',';
        }
        const percentNumberString = `${formatNumber(value.value * 100)}%`;
        mainString += ',"' + percentNumberString + '"';
        deviationString += ',"' + percentNumberString + '"';
        EVRString += ',"' + value.evrData.value + '"';
        let GBSSum = 0;
        value.gbsData.forEach((data: GbsDataCmpType, gbsIndex: number) => {
          let GBSString = '';
          if (dIndex === 0) {
            GBSString += 'GBS' + ',' + data.positionNumber + ',';
            GBSStrings.push(GBSString);
          }
          GBSStrings[gbsIndex] += ',"' + data.value + '"';
          GBSSum += data.value;
        });
        GBSSumString += ',' + GBSSum;
      });

    csv += mainHeader;
    csv += mainString + '\n';

    csv += EVRString + '\n';
    GBSStrings.forEach((gbsString: string) => {
      csv += gbsString + '\n';
    });
    csv += GBSSumString + '\n';
    csv += deviationString + '\n';
    if (commentString !== undefined) {
      csv += '"' + commentString + '"' + '\n';
    }
    csv += '\n';
  });
  downloadCSV(csv, 'Export');
};

const guvExporter = (items: Array<ComparsionProfitAndLossAccountDataType>) => {
  csvStringBuilder(true, items);
};

const standardExporter = (items: Array<ComparisonBaseDataType>) => {
  csvStringBuilder(false, items);
};

const SciComparisonTabList = ({ resourceName }: { resourceName: string }) => {
  const guvContext = useContext(GUVContext);
  const { dateRange } = useWizardContext();
  const [startDate, setStartDate] = useState(dateRange.selectedStartDate);
  const [endDate, setEndDate] = useState(dateRange.selectedEndDate);
  return (
    <ListBase
      sort={{ field: 'positionNumber', order: 'AlphaNumerical' }}
      perPage={25}
      disableSyncWithLocation
      resource={resourceName}
      filterDefaultValues={{
        startDate: startDate,
        endDate: endDate,
      }}
      exporter={guvContext ? guvExporter : standardExporter}>
      <Box sx={{ display: 'block', overflow: 'auto' }}>
        {ListToolbar(startDate, endDate, setStartDate, setEndDate)}
        <CustomDatagrid />
      </Box>
      <Pagination rowsPerPageOptions={[10, 25, 50, 100]} />
    </ListBase>
  );
};

export const sortDataPoints = (a: DataPointType, b: DataPointType) => {
  if (a.date.getTime() < b.date.getTime()) {
    return -1;
  } else if (a.date.getTime() > b.date.getTime()) {
    return 1;
  } else {
    return 0;
  }
};

export const formatNumber = (value: number) => {
  const toLocale = (value: number) =>
    value.toLocaleString('de', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
  if (value > 100) {
    return `>${toLocale(100)}`;
  }
  return toLocale(value);
};

export const datagridRowStyle = (dataPoint: DataPointType) => ({
  backgroundColor: getColorCode(dataPoint.value),
});

export const getColorCode = (deviation: number) => {
  const formattedDeviation = Math.abs(deviation);
  if (formattedDeviation >= 0.03) {
    return '#fee';
  } else if (formattedDeviation >= 0.02) {
    return '#ffe';
  } else {
    return '#efe';
  }
};

export const stickyTableSx = {
  '& .RaDatagrid-tableWrapper': {
    position: 'relative',
    height: 'calc(100vh - 300px)',
    overflow: 'scroll',
    '& .RaDatagrid-table': {
      tableLayout: 'fixed',
      borderCollapse: 'separate',
      '& thead': {
        '& th': {
          position: 'sticky',
          top: '0',
          zIndex: '2',
          backgroundColor: 'white',
        },
        '& th:nth-of-type(1)': {
          width: '120px',
          left: '0',
        },
        '& th:nth-of-type(2)': {
          width: '145px',
          left: '120px',
        },
        '& th:nth-of-type(3)': {
          width: '229px',
          left: '265px',
        },
        '& th:nth-of-type(n+4)': {
          width: '162px',
        },
        '& th:nth-of-type(-n+3)': {
          zIndex: '3',
        },
      },
      '& tbody': {
        '& tr td:nth-of-type(-n+3)': {
          position: 'sticky',
          zIndex: '1',
          backgroundColor: 'white',
        },
        '& tr td:nth-of-type(1)': {
          left: '0',
        },
        '& tr td:nth-of-type(2)': {
          left: '120px',
        },
        '& tr td:nth-of-type(3)': {
          left: '265px',
        },
        '& tr.expanded td': {
          backgroundColor: '#f5f4f4',
        },
      },
    },
    '& .MuiPaper-root': {
      flex: 1,
    },
    '& .MuiAlert-icon': {
      position: 'sticky',
      left: '16px',
    },
    '& .MuiAlert-message': {
      position: 'sticky',
      left: '50px',
    },
    '& .MuiFormControl-root': {
      marginTop: 0,
      marginBottom: 0,
      marginLeft: '8px',
    },
    '& .MuiInputBase-root': {
      flex: 1,
      alignItems: 'flex-start',
    },
  },
};

export default SciComparisonTabList;
