import { DataProvider } from 'react-admin';
import { Row } from 'read-excel-file';
import { v4 as uuidv4 } from 'uuid';
import log from 'loglevel';
import {
  getColumnIndexFromHeaderRow,
  getMonthYearDateFromCell,
  getValueFromCell,
  monthYearDateFormatRegex,
  determineTimeFrame,
  startsWith3Digits,
  getCellsFromRow,
  isNumeric,
} from './ImportHelper';
import { EvrBalanceSheetValueType } from './types/EvrBalanceSheetValueType';
import { EvrBalanceSheetOriginalDataType } from './types/EvrBalanceSheetOriginalDataType';
import { EvrBalanceSheetType } from './types/EvrBalanceSheetType';
import { createLogEntry, OAction, OProcess, OReason } from '../logging/loggingUtility';

const relevantKeyFigures = [
  'Volumen Durchschnitt',
  'Volumen Stichtag',
  'Zinsertrag',
  'Zinsaufwand',
  'Laufende Marge',
  'Liquiditätsbeitrag',
];

const ignorePoNr = ['000', '100', '300', '0'];

enum BusinessType {
  SWAP = 'swap',
  FEST = 'fest',
  VARIABEL = 'variabel',
  BESTAND = 'bestand',
}

const businessTypePoNrMap = new Map<string, string>([
  [BusinessType.VARIABEL, '0'],
  [BusinessType.FEST, '2'],
  [BusinessType.BESTAND, '3'],
  [BusinessType.SWAP, '100'],
]);

const correctPoNrReplacementRegex = new RegExp(/[0-9]([0-9][0-9])/);
const headerRowIndex = 0;

/**
 * Correct position number according to business type.
 * Replace first digit of position number with:
 * -  0 (business type: 'variabel')
 * -  2 (business type: 'fest')
 * -  3 (business type: 'Bestand')
 * -  9 (business type: 'Swap')
 * OR concatenate with balance position
 * in case the position number does not start with 3 digits
 *
 * @param poNr position number
 * @param businessType business type
 * @param balancePo balance position
 * @returns corrected position number
 */
export const correctPoNrAccordingBusinessType = (
  poNr: string,
  businessType: string,
  balancePo: string,
) => {
  if (!businessTypePoNrMap.has(businessType)) {
    createLogEntry(
      poNr,
      businessType,
      OProcess.ImportEVRBalance,
      OAction.Discarded,
      OReason.InvalidBusinessType,
    );
    throw new Error(`Funktionalität für Geschäftstyp ${businessType} nicht definiert!`);
  }
  if (businessType === BusinessType.SWAP) {
    return businessTypePoNrMap.get(businessType) as string;
  }
  if (!startsWith3Digits(poNr)) {
    if (!isNumeric(balancePo)) {
      createLogEntry(
        poNr,
        businessType,
        OProcess.ImportEVRBalance,
        OAction.Discarded,
        OReason.InvalidBalancePosition,
      );
      throw new Error(
        'Eine Bilanzposition ist erforderlich, wenn es sich bei den ersten drei Zeichen der Positionsnummer nicht um Zahlen handelt',
      );
    }
    return businessTypePoNrMap.get(businessType) + balancePo.padStart(2, '0');
  }
  return poNr.replace(correctPoNrReplacementRegex, `${businessTypePoNrMap.get(businessType)}$1`);
};

export const importEvrData = (table: Row[]) => {
  const poNrDateToEvrDataResultMap: Map<string, EvrBalanceSheetType> = new Map();

  const poNrColumnIndex = getColumnIndexFromHeaderRow(table[headerRowIndex], 'Positionsnummer');
  const businessTypeColumnIndex = getColumnIndexFromHeaderRow(
    table[headerRowIndex],
    'Geschäftstyp',
  );
  const balancePoColumnIndex = getColumnIndexFromHeaderRow(table[headerRowIndex], 'Bilanzposition');
  const keyFigureColumnIndex = getColumnIndexFromHeaderRow(table[headerRowIndex], 'Kennzahl');
  const beginTimeSeriesColumnIndex = getColumnIndexFromHeaderRow(
    table[headerRowIndex],
    monthYearDateFormatRegex,
  );
  const timeSeriesKeyArray = table[headerRowIndex]
    .slice(beginTimeSeriesColumnIndex)
    .filter((dateCell) => dateCell !== null)
    .map((dateCell, idx) => getMonthYearDateFromCell(dateCell, idx));

  const tableBody = headerRowIndex + 1;

  for (let currentRowIndex = tableBody; currentRowIndex < table.length; currentRowIndex++) {
    const row: Row = table[currentRowIndex];

    let keyFigure = '';
    let businessType = '';
    let balancePo = '';
    let poNr = '';

    try {
      // fails if cell contains null value
      [keyFigure, businessType, balancePo, poNr] = getCellsFromRow(
        row,
        [keyFigureColumnIndex, businessTypeColumnIndex, balancePoColumnIndex, poNrColumnIndex],
        currentRowIndex,
      ).map((cell) => cell.toString().trim());
    } catch (exception) {
      log.log(`Zeile ${currentRowIndex + 1} ignoriert: ${(exception as Error).message}`);
      createLogEntry(
        poNr,
        keyFigure,
        OProcess.ImportEVRBalance,
        OAction.Ignored,
        OReason.IgnoredValues,
        poNrColumnIndex,
        currentRowIndex,
      );
      continue;
    }
    businessType = businessType.toLocaleLowerCase();
    const containsValuesUnequalZero = row
      .slice(beginTimeSeriesColumnIndex, beginTimeSeriesColumnIndex + timeSeriesKeyArray.length)
      .some((value) => Number(value) !== 0);

    const keyfigureRelevant = relevantKeyFigures.includes(keyFigure);
    const businessTypeRelevant = Object.values<string>(BusinessType).includes(businessType);
    const hasPoNr = poNr.length > 0;
    const poNrRelevant = !ignorePoNr.includes(poNr);
    if (
      !keyfigureRelevant ||
      !businessTypeRelevant ||
      !hasPoNr ||
      !containsValuesUnequalZero ||
      !poNrRelevant
    ) {
      const messages = [];
      !keyfigureRelevant &&
        messages.push(`Kennzahl "${keyFigure}" nicht relevant`) &&
        createLogEntry(
          poNr,
          keyFigure,
          OProcess.ImportEVRBalance,
          OAction.Discarded,
          OReason.InvalidKeyFigure,
          poNrColumnIndex,
          currentRowIndex,
        );
      !businessTypeRelevant &&
        messages.push(`Geschäftstyp "${businessType}" nicht relevant`) &&
        createLogEntry(
          poNr,
          keyFigure,
          OProcess.ImportEVRBalance,
          OAction.Discarded,
          OReason.InvalidBusinessType,
          poNrColumnIndex,
          currentRowIndex,
        );
      !hasPoNr &&
        messages.push('Positionsnummer nicht definiert') &&
        createLogEntry(
          poNr,
          keyFigure,
          OProcess.ImportEVRBalance,
          OAction.Discarded,
          OReason.MissingPositionNumber,
          poNrColumnIndex,
          currentRowIndex,
        );
      !containsValuesUnequalZero &&
        messages.push('Zeitreihe beinhaltet nur Werte gleich 0') &&
        createLogEntry(
          poNr,
          keyFigure,
          OProcess.ImportEVRBalance,
          OAction.Discarded,
          OReason.ZeroValues,
          poNrColumnIndex,
          currentRowIndex,
        );
      !poNrRelevant &&
        messages.push('Positionsnummer ist für den Vergleich nicht relevant') &&
        createLogEntry(
          poNr,
          keyFigure,
          OProcess.ImportEVRBalance,
          OAction.Discarded,
          OReason.IgnoredValues,
          poNrColumnIndex,
          currentRowIndex,
        );
      log.log(`Zeile ${currentRowIndex + 1} ignoriert: ${messages.join(', ')}`);
      continue;
    }

    const slicedPoNr = poNr.slice(0, 3);
    let mappedPoNr = '';
    try {
      mappedPoNr = correctPoNrAccordingBusinessType(slicedPoNr, businessType, balancePo);
    } catch (exception) {
      log.log(`Zeile ${currentRowIndex + 1} ignoriert: ${(exception as Error).message}`);
      continue;
    }
    log.log('poNrOriginal ' + slicedPoNr + ' poNrMapped ' + mappedPoNr);

    for (
      let currentColumnIndex = beginTimeSeriesColumnIndex;
      currentColumnIndex < beginTimeSeriesColumnIndex + timeSeriesKeyArray.length;
      currentColumnIndex++
    ) {
      const cell = row[currentColumnIndex];

      const value = getValueFromCell(cell, currentColumnIndex, currentRowIndex);

      const newEvrValue: EvrBalanceSheetValueType = {
        businessType: businessType,
        originalPositionNumber: poNr,
        value: value,
      };

      const currentTimeSerie =
        timeSeriesKeyArray[currentColumnIndex - beginTimeSeriesColumnIndex].toDateString();
      const poNrDateKey = [mappedPoNr, currentTimeSerie].join(',');

      const evrData = poNrDateToEvrDataResultMap.get(poNrDateKey);

      // entry with mapped position number and date already exists
      if (evrData !== undefined) {
        const evrOrgData = evrData.originalDates.find((data) => data.keyfigureName === keyFigure);

        // keyfigure already exists
        if (evrOrgData !== undefined) {
          evrOrgData.values.push(newEvrValue);
          // add new keyfigure
        } else {
          const newEvrOrgData: EvrBalanceSheetOriginalDataType = {
            keyfigureName: keyFigure,
            values: [newEvrValue],
          };
          evrData.originalDates.push(newEvrOrgData);
        }
        // add new evr data
      } else {
        const newEvrOriginalData: EvrBalanceSheetOriginalDataType = {
          keyfigureName: keyFigure,
          values: [newEvrValue],
        };

        const newEvrData: EvrBalanceSheetType = {
          id: uuidv4(),
          date: timeSeriesKeyArray[currentColumnIndex - beginTimeSeriesColumnIndex],
          mappedPositionNumber: mappedPoNr,
          originalDates: [newEvrOriginalData],
        };
        poNrDateToEvrDataResultMap.set(poNrDateKey, newEvrData);
      }
    }
  }

  return Array.from(poNrDateToEvrDataResultMap.values());
};

const startEvrBalanceSheetImport = (table: Row[], dataProvider: DataProvider) => {
  return dataProvider.clearTableOfResource('evrBalanceSheetData').then(() => {
    const importedEvrData: EvrBalanceSheetType[] = importEvrData(table);
    const timeFrame = determineTimeFrame(importedEvrData);
    dataProvider.createMany('evrBalanceSheetData', { data: importedEvrData });
    return { message: 'File Upload successful.', timeFrame };
  });
};

export default startEvrBalanceSheetImport;
